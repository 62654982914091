//
// Generic Event listener system
//

import {EventsType} from "./events-types";
import {isUndefined} from "./utils/string";
import {
    AROMA,
    CANNABIS_COMPOUNDS,
    COMPOUND,
    MARKET_CBD, MUTAGENICITY, PHARMACO_DYNAMICS,
    PHYSICAL_CHARACTERIZATION_CBD,
    REFERENCES, STABILITY,
} from "./api/Compounds";
import {CBD_PK_INDICES, STUDIES_CBD, STUDIES_METABOLITES, SC_THERAPEUTIC_INDICATIONS} from "./api/Studies";
import {STRAINS, STRAINS_COMP_CONC} from "./api/Strains";
import {
    PRODUCT_ANALYSIS_VALUES,
    PRODUCT_DEGRADATION, PRODUCT_DEGRADATION_COMPOUNDS,
    PRODUCTS_ANALYSIS_METHODS,
    PRODUCTS_ANALYSIS_REPORTS
} from "./api/Products";

class Events {
    private eventListeners: Map<EventsType, Map<string, ((event: any) => void)>> = new Map();

    on( eventType: EventsType,
        listener: ((event: any) => void),
        UUID: string = 'undefined' )
    {
        if (!this.eventListeners.has(eventType)) {
            this.eventListeners.set(eventType, new Map());
            document.addEventListener(eventType, this.handleEvent);
        }

        this.eventListeners.get(eventType)?.set(UUID, listener);
    }

    off( eventType: EventsType,
         UUID: string = 'undefined')
    {
        const listeners = this.eventListeners.get(eventType);
        if (listeners && listeners.has(UUID)) {
            listeners.delete(UUID);
            if (listeners.size === 0) {
                document.removeEventListener(eventType, this.handleEvent);
                this.eventListeners.delete(eventType);
            }
        }
    }

    trigger( eventType: EventsType,
             data: any)
    {
        const event = new CustomEvent(eventType, { detail: data });
        document.dispatchEvent(event);
    }

    triggerByID(id: string) {
        if (isUndefined(id)) return;

        const table = id.split("/")[0];
        const eventTypeMap: Record<string, EventsType> = {
            [AROMA]: EventsType.SEARCH_BY_AROMA,
            [COMPOUND]: EventsType.SEARCH_BY_COMPOUND,
            [CANNABIS_COMPOUNDS]: EventsType.SEARCH_BY_CANNABIS_COMPOUND,
            [MARKET_CBD]: EventsType.SEARCH_BY_CBD_MARKET_DRUG,
            [STUDIES_METABOLITES]: EventsType.SEARCH_BY_METABOLITE,
            [REFERENCES]: EventsType.SEARCH_BY_REFERENCE,
            [STRAINS]: EventsType.SEARCH_BY_STRAIN,
            [STUDIES_CBD]: EventsType.SEARCH_BY_STUDY,
            [SC_THERAPEUTIC_INDICATIONS]: EventsType.SEARCH_BY_STUDY_TI,
            [STRAINS_COMP_CONC]: EventsType.SEARCH_STRAIN_BY_CONCENTRATION,
            [CBD_PK_INDICES]: EventsType.SEARCH_BY_STUDY_PK_INDICES,
            [PHYSICAL_CHARACTERIZATION_CBD]: EventsType.SEARCH_BY_PHYSICAL_PROPERTIES_CBD,
            [MUTAGENICITY]: EventsType.SEARCH_BY_MUTAGENICITY,
            [PHARMACO_DYNAMICS]: EventsType.SEARCH_BY_PHARMACO_DYNAMICS,
            [PRODUCTS_ANALYSIS_REPORTS]: EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT,
            [PRODUCT_ANALYSIS_VALUES]: EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE,
            [PRODUCTS_ANALYSIS_METHODS]: EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD,
            [PRODUCT_DEGRADATION]: EventsType.SEARCH_BY_PRODUCT_DEGRADATION,
            [PRODUCT_DEGRADATION_COMPOUNDS]: EventsType.SEARCH_BY_PRODUCT_DEGRADATION_COMPOUND,
            [STABILITY]: EventsType.SEARCH_BY_STABILITY,
        };

        const event_type = eventTypeMap[table] || EventsType.RELOAD;

        this.trigger(event_type, {_id: id});
    }

    createObject(data: any) {
        if (isUndefined(data) || isUndefined(data._id)) return;
        this.trigger(EventsType.CREATE, data);
    }

    private handleEvent = (event: Event) => {
        const eventType = event.type as EventsType;
        const listeners = this.eventListeners.get(eventType);
        if (listeners) {
            listeners.forEach((listener) => {
                listener(event);
            });
        }
    };
}

const events = new Events();
export default events;





