// utils/basketConfig.ts
import { iBasketItem } from "../components/tabs/basket/types";

type Formatter = (data: any) => string;

interface BasketConfig {
    TITLE?: Formatter;
    INFO?: Formatter;
}

export const basketFormatConfig: { [key: string]: BasketConfig } = {
    "Product Analysis": {
        TITLE: (data) => `${data.COMPOUND_NAME || ''} (${data.INCHIKEY || ''})`.trim()
    },
    "Analysis Report": {
        INFO: (data) => `${data.PRODUCT || ''} ${data.SAMPLE_ID || ''}`.trim()
    }
};

export const formatBasketItem = (newItem: iBasketItem): iBasketItem => {
    const config = basketFormatConfig[newItem.HEADER];
    if (config) {
        const data = newItem.DATA as any;
        if (config.TITLE) newItem.TITLE = config.TITLE(data);
        if (config.INFO) newItem.INFO = config.INFO(data);
    }
    return newItem;
};
