export interface iProductDegradationBase {
    "TYPE": string;
    "NAME": string;
    "SETTINGS": string;
    "DESCRIPTION": string;
}

export const ProductDegradationBase: iProductDegradationBase = {
    DESCRIPTION: "",
    NAME: "",
    SETTINGS: "",
    TYPE: ""
}

export interface iProductDegradationSystemSettings {
    "DATE_CREATED": string;
    "PROGRAM_VERSION": string;
    "KNOWLEDGE_BASE_VERSION": string;
    "KNOWLEDGE_BASE_LAST_MODIFIED_DATE": string;
}

export const ProductDegradationSystemSettings: iProductDegradationSystemSettings = {
    DATE_CREATED: "",
    KNOWLEDGE_BASE_LAST_MODIFIED_DATE: "",
    KNOWLEDGE_BASE_VERSION: "",
    PROGRAM_VERSION: ""
}

export interface iProductDegradationConditionsSettings {
    "CONDITION_SET": string;
    "TEMPERATURE": string;
    "PH": string;
    "WATER": string;
    "OXYGEN": string;
    "METAL": string;
    "RADICAL_INITIATOR": string;
    "PEROXIDE": string;
    "LIGHT": string;
}

export const ProductDegradationConditionsSettings: iProductDegradationConditionsSettings = {
    CONDITION_SET: "",
    LIGHT: "",
    METAL: "",
    OXYGEN: "",
    PEROXIDE: "",
    PH: "",
    RADICAL_INITIATOR: "",
    TEMPERATURE: "",
    WATER: ""
}

export interface iProductDegradationOtherSettings {
    "QUERY_COMPOUNDS_REACTING_WITH_EXCIPIENTS": string;
    "DEGRADANT_REACTING_ON_ITS_OWN": string;
    "DEGRADANTS_REACTING_WITH_EXCIPIENTS": string;
    "QUERY_COMPOUNDS_REACTING_ON_ITS_OWN_OR_WITH_ANOTHER_QUERY_STRUCTURE": string;
    "QUERY_COMPOUND_REACTING_WITH_ITSELF": string;
    "DEGRADANT_REACTING_WITH_ITSELF": string;
    "QUERY_COMPOUNDS_REACTING_WITH_DEGRADANTS": string;
    "MAXIMUM_MONOMER_COUNT": string;
    "MAXIMUM_TOTAL_NUMBER_OF_DEGRADANTS": string;
    "MAXIMUM_DEGRADANTS_LIMIT_REACHED": string;
    "MAXIMUM_NUMBER_OF_STEPS_IN_A_PATHWAY": string;
    "MINIMUM_SCORE": string;
    "PATHWAY_LIKELIHOOD": string;
}

export const ProductDegradationOtherSettings: iProductDegradationOtherSettings = {
    DEGRADANTS_REACTING_WITH_EXCIPIENTS: "",
    DEGRADANT_REACTING_ON_ITS_OWN: "",
    DEGRADANT_REACTING_WITH_ITSELF: "",
    MAXIMUM_DEGRADANTS_LIMIT_REACHED: "",
    MAXIMUM_MONOMER_COUNT: "",
    MAXIMUM_NUMBER_OF_STEPS_IN_A_PATHWAY: "",
    MAXIMUM_TOTAL_NUMBER_OF_DEGRADANTS: "",
    MINIMUM_SCORE: "",
    PATHWAY_LIKELIHOOD: "",
    QUERY_COMPOUNDS_REACTING_ON_ITS_OWN_OR_WITH_ANOTHER_QUERY_STRUCTURE: "",
    QUERY_COMPOUNDS_REACTING_WITH_DEGRADANTS: "",
    QUERY_COMPOUNDS_REACTING_WITH_EXCIPIENTS: "",
    QUERY_COMPOUND_REACTING_WITH_ITSELF: ""
}

export interface iProductDegradationTransformationsSettings {
    "TESTING_AGAINST": string;
}

export const ProductDegradationTransformationsSettings: iProductDegradationTransformationsSettings = {
    TESTING_AGAINST: ""
}

export interface iProductDegradationAppliedFilters {
    "SHOW_DUPLICATES": string;
    "SHOW_ONLY_EXCIPIENT_INTERACTIONS": string;
    "SHOW_STEP_SCORE": string;
    "TRANSFORMATION_GROUPS": string;
    "CONDITION_TRIGGERS": string;
    "MINIMUM_PATHWAY_SCORE": string;
    "HIGHEST_DISPLAYED_STEP": string;

    "EXACT_MASS_SINGLE_VALUE": string;
    "EXACT_MASS_RANGE": string;
    "EXACT_MASS_MASS_TOLERANCE": string;

    "AVERAGE_MASS_SINGLE_VALUE": string;
    "AVERAGE_MASS_RANGE": string;
    "AVERAGE_MASS_MASS_TOLERANCE": string;
}

export const ProductDegradationAppliedFilters: iProductDegradationAppliedFilters = {
    AVERAGE_MASS_MASS_TOLERANCE: "",
    AVERAGE_MASS_RANGE: "",
    AVERAGE_MASS_SINGLE_VALUE: "",
    CONDITION_TRIGGERS: "",
    EXACT_MASS_MASS_TOLERANCE: "",
    EXACT_MASS_RANGE: "",
    EXACT_MASS_SINGLE_VALUE: "",
    HIGHEST_DISPLAYED_STEP: "",
    MINIMUM_PATHWAY_SCORE: "",
    SHOW_DUPLICATES: "",
    SHOW_ONLY_EXCIPIENT_INTERACTIONS: "",
    SHOW_STEP_SCORE: "",
    TRANSFORMATION_GROUPS: ""

}

export interface iProductDegradationStructureFilters {

    "MATCH_TYPE": string;
    "STRUCTURE_FILTER": string;
}

export const ProductDegradationStructureFilters: iProductDegradationStructureFilters = {
    MATCH_TYPE: "",
    STRUCTURE_FILTER: ""
}

export interface iProductDegradation extends iProductDegradationBase,
    iProductDegradationSystemSettings,
    iProductDegradationConditionsSettings,
    iProductDegradationOtherSettings,
    iProductDegradationAppliedFilters,
    iProductDegradationTransformationsSettings,
    iProductDegradationStructureFilters {
}

export const ProductDegradation: iProductDegradation = {
    ...ProductDegradationBase,
    ...ProductDegradationSystemSettings,
    ...ProductDegradationConditionsSettings,
    ...ProductDegradationOtherSettings,
    ...ProductDegradationAppliedFilters,
    ...ProductDegradationTransformationsSettings,
    ...ProductDegradationStructureFilters,
};

export interface iProductDegradationCompounds {
    "PRODUCT_ID": string;
    "COMPOUND_NAME": string;
    "INCHIKEY": string;
    "PARENT_ID": string;
    "PARENT_2_ID": string;
    "INTERMEDIATE_TYPES": string;
    "PATHWAY_SCORES": string;
    "TRANSFORMATION_ID": string;
    "TRANSFORMATION_NAME": string;
    "TRANSFORMATION_GROUP": string;
    "CONDITION_TRIGGERS": string;
    "EXACT_MASS": string;
    "AVERAGE_MASS": string;
    "MASS_DIFFERENCE": string;
    "FORMULA": string;
    "FORMULA_GAIN": string;
    "FORMULA_LOSS": string;
    "TRANSFORMATION_DESCRIPTION": string;
    "TRANSFORMATION_REFERENCES": string;
    "TRANSFORMATION_COMMENTS": string;
}

export const ProductDegradationCompounds: iProductDegradationCompounds = {
    AVERAGE_MASS: "",
    CONDITION_TRIGGERS: "",
    EXACT_MASS: "",
    FORMULA: "",
    FORMULA_GAIN: "",
    FORMULA_LOSS: "",
    INCHIKEY: "",
    INTERMEDIATE_TYPES: "",
    MASS_DIFFERENCE: "",
    COMPOUND_NAME: "",
    PARENT_2_ID: "",
    PARENT_ID: "",
    PATHWAY_SCORES: "",
    PRODUCT_ID: "",
    TRANSFORMATION_COMMENTS: "",
    TRANSFORMATION_DESCRIPTION: "",
    TRANSFORMATION_GROUP: "",
    TRANSFORMATION_ID: "",
    TRANSFORMATION_NAME: "",
    TRANSFORMATION_REFERENCES: ""

}

export interface iStability {
    INCHIKEY: string;
    COMPOUND_NAME: string;
    STUDY_TITLE: string;
    DATE: string;
    SAMPLING_DESCRIPTION: string;
    METHOD_DESCRIPTION: string;
    TREATMENT: string;
    TREATMENT_DESCRIPTION: string;
    VARIETY: string;
    VARIETY_BATCH: string;
    TIMEPOINT_WEEKS: string;
    ANALYSIS_TYPE: string;
    MEASURE_TYPE: string;
    RELATION: string;
    VALUE: string;
    UNIT: string;
    STD_DEV: string;
    LOD: string;
    LOQ: string;
    N_BIO_REPLICATES: string;
    N_TECHNICAL_REPLICATES: string;
    COMMENT: string;
    REF_ARTICLE: string;
}

export const Stability: iStability = {
    INCHIKEY: "",
    COMPOUND_NAME: "",
    STUDY_TITLE: "",
    DATE: "",
    SAMPLING_DESCRIPTION: "",
    METHOD_DESCRIPTION: "",
    TREATMENT: "",
    TREATMENT_DESCRIPTION: "",
    VARIETY: "",
    VARIETY_BATCH: "",
    TIMEPOINT_WEEKS: "",
    ANALYSIS_TYPE: "",
    MEASURE_TYPE: "",
    RELATION: "",
    VALUE: "",
    UNIT: "",
    STD_DEV: "",
    LOD: "",
    LOQ: "",
    N_BIO_REPLICATES: "",
    N_TECHNICAL_REPLICATES: "",
    COMMENT: "",
    REF_ARTICLE: ""
};
