import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { PRODUCT_ANALYSIS_VALUES, deleteDoc, getObjectsByRegEx, save } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { ProductAnalysisValue } from "./types";

const _ProductAnalisysValueCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                                      setError,
                                                                      setData,
                                                                      data,
                                                                      isActive,
                                                                      setTitle,
                                                                      searchBy,
                                                                      searchByDoc }) => {
    const UUID = '_ProductAnalisysValueCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(PRODUCT_ANALYSIS_VALUES, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(PRODUCT_ANALYSIS_VALUES, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(PRODUCT_ANALYSIS_VALUES, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchByTextEvent = useCallback(async (event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES,
            'TEST_METHOD_CODE,REPORT_PROJECT_ID,PRODUCT,SAMPLE_ID', event.detail);
    }, [searchBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        searchByTextEvent(event);
    }, [searchByTextEvent]);


    const searchByObjectEvent = useCallback((event: { detail: any }) => {
        setData(event.detail);
        setIsShow(true);
        setTitle(event.detail.INCHIKEY || 'PRODUCT ANALYSIS');
    }, [setData, setIsShow, setTitle]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE, searchByObjectEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByObjectEvent]);

    // RENDER
    return (
        <>
            {data.COMPOUND_NAME && (
                <React.Fragment>
                    <b>COMPOUND NAME</b>: {data.COMPOUND_NAME}
                    <br />
                </React.Fragment>
            )}
            {renderFields(ProductAnalysisValue, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal save={saveData}
                                   data={data}
                                   schema={ProductAnalysisValue} />
                    <DisplayDeleteModal deleteDoc={deleteData}
                                        data={data}
                                        schema={ProductAnalysisValue} />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const ProductAnalisysValueCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_ProductAnalisysValueCard}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductAnalisysValueCard);
