import React, {useCallback, useEffect} from 'react';

import {Box, IconButton, Typography} from "@mui/material";
import {FileDownload as FileDownloadIcon, Visibility as VisibilityIcon} from "@mui/icons-material";

import {MaterialReactTable} from 'material-react-table';

import Events from '../../../events';
import {EventsType} from '../../../events-types';

import {
    getByFilters,
    getObjectsByRegEx,
    MUTAGENICITY,
} from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn, ColumnFiltersState} from "@tanstack/react-table";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";

import {Mutagenicity} from "./types";

const _MutagenicityTableCard: React.FC<iBaseInnerCardProps> = ({ data ,
                                                                      setColumnFilters, columnFilters,
                                                                      // setTextSearch, textSearch,
                                                                      isActive,
                                                                      searchListBy, searchListByFilters
                                                                  }) => {

    const UUID = '_MutagenicityTableCard'

    const columnNames = Object.keys(Mutagenicity);
    const columnsToView = [ 'MODEL_NAME', 'MODEL_ENDPOINT', 'COMPOUND_NAME',
                                    'RESULT_MUTAGENICITY','CONFIDENCE','ALERT_COUNT',
                                    'POSITIVE_HYPOTHESIS_COUNT','NEGATIVE_HYPOTHESIS_COUNT' ];

    const eventMapping: CellEventMapping = {
        'INCHIKEY': EventsType.SEARCH_BY_INCHIKEY
    };

    const columns = getColumns(Mutagenicity, 0, createCellsWithEvents(eventMapping));
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);


    // SEARCH -------------

    const _search = useCallback((text: string ) => {
        if (text.length > 0) {
            searchListBy?.(getObjectsByRegEx, MUTAGENICITY,
                'MODEL_NAME,CONFIDENCE,MODEL_ENDPOINT,INCHIKEY,REASONING_OVERVIEW,REASONING_DESCRIPTION', text);
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string; }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback((event: { detail: { component: string; filters: any[]; }; }) => {
        if (event.detail.component === MUTAGENICITY) {
            searchListByFilters?.(getByFilters, MUTAGENICITY, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string; }) => {
        searchListBy?.(getObjectsByRegEx, MUTAGENICITY, 'INCHIKEY', event.detail);
    }, [searchListBy]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
        };
    }, [isActive, searchEvent, searchWizard, searchByInchiKeyEvent]);


    // RENDER ----------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (

        <MaterialReactTable
            columns={columns}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'INCHIKEY',
                    'COMPOUND_NAME',
                    'MODEL_NAME',
                    'MODEL_VERSION',
                    'PROGRAM_VERSION',
                    'REPORT_DATE',
                    'PREDICTION_AUTHOR',
                    'PREDICTION_DATE',
                    'MODEL_ENDPOINT',
                    'KNOWLEDGE_BASES',
                    'KNOWLEDGE_BASE_MODIFIED_DATE',
                    'KNOWLEDGE_BASE_CERTIFIED_BY',
                    'SPECIES',

                    'ALERT_COUNT',
                    'RESULT_MUTAGENICITY',

                    'CONFIDENCE',

                    'EQUIVOCAL_LEVEL',
                    'REASONING_TYPE',
                    'SENSITIVITY_LEVEL',

                    'POSITIVE_HYPOTHESIS_COUNT',
                    'NEGATIVE_HYPOTHESIS_COUNT',
                    'POSITIVE_HYPOTHESIS_COUNT_OVERRULED',
                    'NEGATIVE_HYPOTHESIS_COUNT_OVERRULED',
                    'TOTAL_HYPOTHESIS_COUNT'
                ],
            }}


            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>Reasoning Overview:</b> {row.original.REASONING_OVERVIEW}</Typography>
                    <Typography><b>Reasoning Description:</b> {row.original.REASONING_DESCRIPTION}</Typography>
                </Box>
            )}


            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* ROW ACTION   */

            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_MUTAGENICITY, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>

                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Mutagenicity")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Mutagenicity")}

                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>

                </Box>
            )}
        />
    );
};

const MutagenicityTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MutagenicityTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(MutagenicityTableCard);
