import React, {useEffect} from 'react';
import data from './changes.json';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";


const _ChangeLogCard: React.FC<iBaseInnerCardProps> = ({setIsShow, setData}) => {

    useEffect(() => {
        setIsShow(true);
        setData(data);
    },[]);


    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const filteredData = data.filter(({ date }) => {
        const [year, month, day] = date.split('.').map(Number);
        const itemDate = new Date(year, month - 1, day);
        return itemDate >= oneYearAgo;
    });

    return (
        <>
                {filteredData.map(({ version, date, author, items }, index) => (
                    <div key={index} style={{ textAlign: 'left', maxHeight: '500px', overflowY: 'auto'}}>
                        <h3><b>Version:</b> {version}</h3>
                        <div><b>Date: </b>{date} | <b>Author:</b> {author}</div>
                        <ul>
                            {items.map((item, itemIndex) => (
                                <li key={itemIndex}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
        </>
    );
};
const ChangeLogCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger, showBasket, storageValue }) => {
    return <CardWithBaseComponent cardComponent={_ChangeLogCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  storageValue={storageValue}/>;
};


export default React.memo(ChangeLogCard);
