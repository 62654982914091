import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    PRODUCT_DEGRADATION_COMPOUNDS,
    deleteDoc,
    getObjectByDoc,
    getObjectsByRegEx,
    save
} from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { Button } from "@mui/material";
import { ProductDegradationCompounds } from "./types";

const _ProductDegradationCompoundCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                                            setError,
                                                                            setData,
                                                                            data,
                                                                            isActive,
                                                                            setTitle,
                                                                            searchBy,
                                                                            searchByDoc }) => {
    const UUID = '_ProductDegradationCompoundCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(PRODUCT_DEGRADATION_COMPOUNDS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(PRODUCT_DEGRADATION_COMPOUNDS, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(PRODUCT_DEGRADATION_COMPOUNDS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchByTextEvent = useCallback(async (event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, PRODUCT_DEGRADATION_COMPOUNDS,
            'NAME,INCHIKEY,' +
            'TRANSFORMATION_NAME,TRANSFORMATION_GROUP,TRANSFORMATION_GROUP,CONDITION_TRIGGERS,' +
            'TRANSFORMATION_DESCRIPTION,TRANSFORMATION_REFERENCES,TRANSFORMATION_COMMENTS', event.detail);
    }, [searchBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        searchByTextEvent(event);
    }, [searchByTextEvent]);

    const searchByObjectEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, PRODUCT_DEGRADATION_COMPOUNDS, event.detail, true, 'PRODUCT DEGRADATION COMPOUND');
    }, [searchByDoc]);

    const searchByInchiKeyEvent = useCallback(async (event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, PRODUCT_DEGRADATION_COMPOUNDS, 'INCHIKEY', event.detail);
    }, [searchBy]);

    // EVENTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_COMPOUND, searchByObjectEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_COMPOUND, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByInchiKeyEvent, searchByObjectEvent]);

    // RENDER
    return (
        <>
            <React.Fragment key="LOAD_DEGRADATION_COMPOUNDS_of_PRODUCT">
                <Button variant="contained" size="small"
                        style={{ margin: '5px' }}
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, data.PRODUCT_ID);
                        }}>
                    LOAD PRODUCT of the DEGRADATION COMPOUND
                </Button>

            </React.Fragment>
            <br/>
            {renderFields(ProductDegradationCompounds, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal save={saveData}
                                   data={data}
                                   schema={ProductDegradationCompounds} />
                    <DisplayDeleteModal deleteDoc={deleteData}
                                        data={data}
                                        schema={ProductDegradationCompounds} />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const ProductDegradationCompoundCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_ProductDegradationCompoundCard}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductDegradationCompoundCard);
