import React, { useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ChartBarProductsProps } from './types';
import ProductSelector from './ProductSelector';
import { transformData, getTooltipCallbacks } from './Chart.Common';

const ChartBarTerpenes: React.FC<ChartBarProductsProps> = ({ data }) => {
    const filteredItems = useMemo(() => data.filter(item => item.HEADER.startsWith("Concentration of terpenes")), [data]);

    const [visualizationMode, setVisualizationMode] = useState<'direct' | 'percentage' | 'normalized' | 'logarithmic'>('logarithmic');
    const [selectedTitles, setSelectedTitles] = useState<string[]>([]);
    const [showInCommon, setShowInCommon] = useState(true);

    const handleModeChange = (event: SelectChangeEvent<'direct' | 'percentage' | 'normalized' | 'logarithmic'>) => {
        setVisualizationMode(event.target.value as 'direct' | 'percentage' | 'normalized' | 'logarithmic');
    };

    const chartData = transformData(
        filteredItems,
        selectedTitles,
        visualizationMode,
        (item) => `${item.TITLE || 'Unknown'}`,
        (row) => Number(row.CONCENTRATION) || 0,
        (row) => row.NAME,
        (row) => null, // No STDEV in terpene data
        (item) => '% Dry Weight',
        showInCommon
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `Terpene Concentrations (${visualizationMode.charAt(0).toUpperCase() + visualizationMode.slice(1)})`,
            },
            tooltip: {
                callbacks: getTooltipCallbacks(
                    filteredItems,
                    (item) => `${item.TITLE || 'Unknown'}`,
                    (row) => row.NAME,
                    (row) => Number(row.CONCENTRATION) || 0,
                    (row) => null,
                    (item) => '% Dry Weight',
                    visualizationMode
                ),
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Terpenes',
                },
            },
            y: {
                title: {
                    display: true,
                    text: visualizationMode === 'percentage' ? '% of Batch Total' :
                        visualizationMode === 'normalized' ? 'Normalized Value (%)' :
                            visualizationMode === 'logarithmic' ? 'Log10 Normalized (%)' : '% Dry Weight',
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                <ProductSelector
                    items={filteredItems}
                    selectedProducts={selectedTitles}
                    setSelectedProducts={setSelectedTitles}
                    showInCommon={showInCommon}
                    setShowInCommon={setShowInCommon}
                />
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Visualization Mode</InputLabel>
                    <Select
                        value={visualizationMode}
                        onChange={handleModeChange}
                        label="Visualization Mode"
                        size="small"
                    >
                        <MenuItem value="direct">Direct Value</MenuItem>
                        <MenuItem value="percentage">% Range</MenuItem>
                        <MenuItem value="normalized">Unitary Normalization</MenuItem>
                        <MenuItem value="logarithmic">Logarithmic Scale</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                {filteredItems.length > 0 && chartData.labels.length > 0 ? (
                    <Box sx={{ width: '80%' }}>
                        <Bar data={chartData} options={options} />
                    </Box>
                ) : (
                    <Typography>No valid terpene concentration data available.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ChartBarTerpenes;
