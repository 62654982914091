import React, { useCallback, useEffect } from 'react';
import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { FileDownload as FileDownloadIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { PHYSICAL_CHARACTERIZATION_CBD, getByFilters, getObjectsByRegEx } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from '../../types';
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { Physical_characterization_CBD } from "./types";

const _PhysicalCharacterizationCBDTable: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                                              setData, data, isActive,
                                                                              setColumnFilters, columnFilters,
                                                                              searchListByFilters,
                                                                              setTitle, setSubtitle, searchListBy }) => {
    const UUID = '_PhysicalCharacterizationCBDTable';

    const columnNames = Object.keys(Physical_characterization_CBD);
    const columnsToView = ['SUPPLIER', 'SUBSTANCE', 'INCHIKEY', 'PRODUCT_RM_NUMBER', 'CRYSTALLINITY', 'TG', 'DOMINANT_PARTICLE_SHAPE'];
    const columns = getColumns(Physical_characterization_CBD);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            searchListBy?.(getObjectsByRegEx, PHYSICAL_CHARACTERIZATION_CBD, 'SUBSTANCE,INCHIKEY,SUPPLIER', text);
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === PHYSICAL_CHARACTERIZATION_CBD) {
            searchListByFilters?.(getByFilters, PHYSICAL_CHARACTERIZATION_CBD, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive, searchEvent, searchWizard]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableColumnFilterModes
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
            }}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PHYSICAL_PROPERTIES_CBD, row.original);
                        }}>
                        <VisibilityIcon />
                    </IconButton>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Physical Characterization CBD")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Physical Characterization CBD")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const PhysicalCharacterizationCBDTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PhysicalCharacterizationCBDTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PhysicalCharacterizationCBDTable);
