import React, { useCallback, useEffect } from 'react';
import { Box, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { FileDownload as FileDownloadIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { getByFilters, getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { ProductAnalysisReport } from "./types";

const _PAReportsTable: React.FC<iBaseInnerCardProps> = ({ data,
                                                            setColumnFilters, columnFilters,
                                                            isActive,
                                                            searchListBy, searchListByFilters }) => {
    const UUID = '_PAReportsTable';

    const columnNames = Object.keys(ProductAnalysisReport);
    const columnsToView = ['ANALYSIS_TYPE', 'REPORT_SOURCE', 'REPORT_PROJECT_ID', 'PRODUCT', 'DATE'];

    const eventMapping: CellEventMapping = {
        'ANALYSIS_TYPE': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE,
        'REPORT_SOURCE': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_SOURCE,
        'REPORT_PROJECT_ID': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID,
        'SAMPLE_ID': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_SAMPLE_ID,
        'PRODUCT': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_PRODUCT,
    };

    const columns = getColumns(ProductAnalysisReport, 0, createCellsWithEvents(eventMapping));
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS,
                'ANALYSIS_TYPE,SOURCE,REPORT_PROJECT_ID,MATRIX_CODE,SAMPLE_PREPARATION,PRODUCT', text);
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === PRODUCTS_ANALYSIS_REPORTS) {
            searchListByFilters?.(getByFilters, PRODUCTS_ANALYSIS_REPORTS, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    const searchReportByAnalysisReportID = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS, 'REPORT_PROJECT_ID', event.detail);
    }, [searchListBy]);

    const searchReportByAnalysisSource = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS, 'REPORT_SOURCE', event.detail);
    }, [searchListBy]);

    const searchReportByAnalysisType = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS, 'ANALYSIS_TYPE', event.detail);
    }, [searchListBy]);

    const searchReportByProduct = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS, 'PRODUCT', event.detail);
    }, [searchListBy]);

    const searchReportBySampleID = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS, 'SAMPLE_ID', event.detail);
    }, [searchListBy]);

    // EVENTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE, searchReportByAnalysisType, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID, searchReportByAnalysisReportID, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_SOURCE, searchReportByAnalysisSource, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_PRODUCT, searchReportByProduct, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_SAMPLE_ID, searchReportBySampleID, UUID);
        }

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_SOURCE, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_PRODUCT, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_SAMPLE_ID, UUID);
        };
    }, [isActive, searchEvent, searchWizard, searchReportByAnalysisType, searchReportByAnalysisReportID,
        searchReportByAnalysisSource, searchReportByProduct, searchReportBySampleID]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'ANALYSIS_TYPE',
                    'REPORT_SOURCE',
                    'REPORT_PROJECT_ID',
                    'DATE',
                    'MATRIX_CODE',
                    'SAMPLE_PREPARATION',
                    'PRODUCT',
                    'SAMPLE_ID',
                    'PARAMETERS_OF_SMOKE_OR_AEROSOL_GENERATION',
                    'HEATING_DEVICE',
                ],
            }}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>Sample Preparation:</b> {row.original.SAMPLE_PREPARATION}</Typography>
                </Box>
            )}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Compounds")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "ProductsAnalysisMethods")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const ProductsAnalysisReportsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PAReportsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductsAnalysisReportsTable);
