import { getCurrentUser, signInWithRedirect, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { jwtDecode } from "jwt-decode";

let user: any | null = null;


export const getCurrentUserTokenId = async (): Promise<string | null> => {
    try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        if (token) {
            return token;
        } else {
            throw new Error('User session not found');
        }
    } catch (error) {
        console.log('Error getting current user token ID:', error);
        if (error instanceof Error && error.message === 'User session not found') {
            await signOut(); // Sign out to clear any invalid state
            await signInWithRedirect(); // Redirect to login
        }
        return null;
    }
};

export const getCurrentUserNameAndRoles = async (): Promise<{ user: string | null, roles: string[], login: string | null }> => {
    try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        if (token) {
            const name = getTokenPropertyByKeyName(token, 'name');
            const login = getTokenPropertyByKeyName(token, 'email');
            const roles = extractValues(getTokenPropertyByKeyName(token, 'custom:groups'));
            const roles2 = extractValues(getTokenPropertyByKeyName(token, 'cognito:groups'));
            return { user: name, roles: roles.concat(roles2), login: login };
        } else {
            throw new Error('User session not found');
        }
    } catch (error) {
        console.log('Error getting current user roles:', error);
        return { user: null, roles: [], login: null };
    }
};

export const getTokenPropertyByKeyName = (token: string, keyName: string): any | null => {
    const decodedToken: { [key: string]: any } = jwtDecode(token);
    return decodedToken[keyName] || null;
};

export const signOutFn = async () => {
    try {
        await signOut({ global: true }); // Clear all auth state globally
        user = null; // Clear cached user
        await signInWithRedirect(); // Redirect to login page
    } catch (error) {
        console.log('Sign out error:', error);
        await signInWithRedirect(); // Force redirect even on error
    }
};

function extractValuesFromString(input: string): string[] {
    if (input == null) return [];

    const prefix = "PMIGLOBALURCannaBoRN";
    const suffixes = ["DEVIGA", "QASIGA", "PRDIGA"];

    const values: string[] = [];

    const regexPattern = new RegExp(`${prefix}(.*?)(${suffixes.join('|')})`, 'g');
    const matches = input.match(regexPattern);

    if (matches) {
        for (const match of matches) {
            const value = match.replace(prefix, '').replace(new RegExp(`(?:${suffixes.join('|')})$`), '');
            values.push(value);
        }
    }

    return values;
}

function extractValuesFromArray(inputArray: string[]): string[] {
    const values: string[] = [];

    for (const input of inputArray) {
        const extractedValues = extractValuesFromString(input);
        values.push(...extractedValues);
    }

    return values;
}

function extractValues(input: any): string[] {
    if (input == null) return [];

    if (typeof input === 'string') {
        return extractValuesFromString(input);
    } else {
        return extractValuesFromArray(input);
    }
}
