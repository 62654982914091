import React, { useCallback, useEffect } from 'react';
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { STRAINS, getStrainsRegEx, getFullTable, getByFilters } from '../../../api/Strains';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel } from "../../../utils/export_xlsx";
import { isValidIchiKey } from "../../../utils/string";
import { Strain as StrainObject } from "./types";

const _StrainsTable: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                          setData, data, isActive,
                                                          setColumnFilters, columnFilters,
                                                          setTitle, setSubtitle, searchListBy, searchListByFilters }) => {
    const UUID = '_StrainsTable';

    const columnNames = Object.keys(StrainObject);
    const columnsToView = ['VARIETY_NAME', 'COUNTRY', 'COUNTRY_REGION', 'TOTAL_THC', 'TOTAL_CBD', 'TOTAL_CBG', 'TOTAL_CBC'];
    const columns = getColumns(StrainObject);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // EXPORT FUNCTION
    const _exportToExcel = useCallback(async (data: any[]) => {
        const strainIds = data.map(obj => String(obj.STRAIN_ID || obj.original?.STRAIN_ID));
        getFullTable(strainIds)
            .then(full_data => {
                exportToExcel(full_data, 'Varieties with Concentrations');
            })
            .catch(error => {
                setError(error);
            });
    }, [setError]);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            if (isValidIchiKey(text)) {
                // Note: The original code had a commented-out call to searchByInchiKey(text).
                // Since it’s not implemented, I’ve left it out. Add if needed.
            } else {
                searchListBy?.(getStrainsRegEx, STRAINS, 'VARIETY_NAME', text);
            }
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        if (isActive) {
            _search(event.detail);
        }
    }, [isActive, _search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (isActive && event.detail.component === STRAINS) {
            searchListByFilters?.(getByFilters, STRAINS, event.detail.filters, 'wizard result');
        }
    }, [isActive, searchListByFilters]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [searchEvent, searchWizard]); // Removed isActive from dependencies as it’s checked inside the callbacks

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableColumnFilterModes
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
            }}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_STRAIN, row.original);
                            }}>
                        ID {row.original.STRAIN_ID}
                    </Button>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => _exportToExcel(data)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => {
                            let _data = table.getSelectedRowModel().rows.map((row) => row.original);
                            _exportToExcel(_data);
                        }}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const StrainsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_StrainsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(StrainsTable);
