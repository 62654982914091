import React, { useCallback, useEffect, useMemo } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { getDataByName } from "../../../api/Pubchem";
import { Box } from "@mui/material";
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import Button from "@mui/material/Button";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotUndefined } from "../../../utils/string";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { exportToExcel } from "../../../utils/export_xlsx";

const _PubChemListCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data,
                                                             setColumnFilters, columnFilters, isActive,
                                                             setTitle }) => {
    const UUID = '_PubChemListCard';

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'iupacname',
                header: 'NAME',
                muiTableHeadCellProps: { sx: { color: 'green' } },
            }
        ],
        [],
    );

    // SEARCH FUNCTIONS
    const searchBy = useCallback((key: string, value: string) => {
        setIsShow(false);
        getDataByName(key, value)
            .then(response => {
                if (isNotUndefined(response.data) && response.data.length > 0) {
                    setTitle(value);
                    setData(response.data);
                    setIsShow(true);
                } else if (response.error) {
                    setError(response.error);
                    setIsShow(true);
                }
            })
            .catch(ex => {
                setError(ex);
                setIsShow(true);
            });
    }, [setIsShow, setTitle, setData, setError]);

    const _search = useCallback((text: string) => {
        searchBy('compound', text);
    }, [searchBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
        };
    }, [isActive, searchEvent]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableRowSelection
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{ density: 'compact', columnVisibility: { 'NAME': true, 'cid': false } }}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_PUBCHEM_CID, row.original.cid);
                                Events.trigger(EventsType.SEND_PUBCHEM_COMPOUND, row.original);
                            }}>
                        CID {row.original.cid}
                    </Button>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Pubchem Substances")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "Pubchem Substances")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const PubChemListCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PubChemListCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PubChemListCard);
