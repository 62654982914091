import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { AROMA, getObjectsByRegEx, save, deleteDoc, getObjectByDoc } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { isNotEmpty, isValidIchiKey } from "../../../utils/string";
import { useRoles } from "../../../App.Context";
import { Aroma } from "./types";

const _AromaCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, searchBy, searchByDoc, isActive }) => {
    const UUID = '_AromaCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(AROMA, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(AROMA, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(AROMA, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchEvent = useCallback((event: { detail: string }) => {
        if (event.detail.length > 0) {
            if (isValidIchiKey(event.detail)) {
                searchBy?.(getObjectsByRegEx, AROMA, 'INCHIKEY', event.detail);
            } else {
                searchBy?.(getObjectsByRegEx, AROMA, 'ODOR,CHEMICAL_TYPE,COMPOUND_NAME', event.detail);
            }
        }
    }, [searchBy]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, AROMA, event.detail, true, 'COMPOUND_NAME');
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_AROMA, searchByCompoundEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_AROMA, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByCompoundEvent]);

    // RENDERS
    return (
        <p>
            {renderFields(Aroma, data)}

            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={Aroma}
                        excludeListParameters={["COMPOUND_NAME"]}
                    />
                    <DisplayDeleteModal
                        deleteDoc={deleteData}
                        data={data}
                        schema={Aroma}
                    />
                </>
            )}

            <AuditModal id={data._id} undo={undo} />
        </p>
    );
};

const AromaCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_AromaCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(AromaCard);
