import React, {useCallback, useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {deleteDoc, getObjectsByRegEx, save, MUTAGENICITY} from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty} from "../../../utils/string";
import FormEditModal from "../../common/FormEditModal";
import {renderFields} from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";

import {Mutagenicity} from "./types";

const _MutagenicityCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                            setData, data , isActive,
                                                            setTitle, setSubtitle, searchBy, searchByDoc }) => {
    const UUID = '_MutagenicityCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK
    const saveData = useCallback(async (data: any) => {
        save(MUTAGENICITY, data).then((res) => {
            setData(res);
        }).catch(setError);
    }, [setData, setError]);


    const deleteData = async (data: any) => {
        deleteDoc(MUTAGENICITY, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }


    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(MUTAGENICITY, data).then((res) => {
            setData(res);
        }).catch(setError);
    }
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    // SEARCH ------------------------

    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            searchBy?.(getObjectsByRegEx, MUTAGENICITY, 'MODEL_NAME,MODEL_ENDPOINT,PROGRAM_VERSION', text);
        }
    }, [searchBy]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string }) => {
        if (event.detail.length > 0 && data.INCHIKEY !== event.detail) {
            searchBy?.(getObjectsByRegEx, MUTAGENICITY, 'INCHIKEY', '^' + event.detail + '$');
        }
    }, [searchBy, data]);

    const searchEvent = useCallback(async (event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchByCompoundEvent = useCallback(async (event: { detail: any }) => {
        setData(event.detail);
        setIsShow(true);
    }, [setData, setIsShow]);

    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive)  {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_BY_MUTAGENICITY, searchByCompoundEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_MUTAGENICITY, UUID);

        };
    }, [isActive, createEvent, searchEvent, searchByInchiKeyEvent, searchByCompoundEvent]);

    // RENDERS --------------


    return(
        <p>
            {renderFields(Mutagenicity, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={Mutagenicity}
                        excludeListParameters={["COMPOUND_NAME"]}/>

                    <DisplayDeleteModal deleteDoc={deleteData}
                                        data={data}
                                        schema={Mutagenicity}/>
                </>
            )}

            <AuditModal id={data._id} undo={undo}/>
        </p>
    );


};


const MutagenicityCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MutagenicityCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(MutagenicityCard);

