import React, { useCallback, useEffect, useState } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    STRAINS,
    getStrainsRegEx,
    save,
    deleteDoc,
    getObjectByDoc,
    getStrainIdFromConcentration
} from '../../../api/Strains';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty, isNotUndefined } from "../../../utils/string";
import { ChartPie } from "../../graphics/ChartPie";
import FormEditModal from "../../common/FormEditModal";
import { renderFields } from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { Strain as StrainObject } from "./types";

const _StrainCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, searchBy, searchByDoc, isActive }) => {
    const UUID = '_StrainCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    const [styleParam, setStyleParam] = useState<React.CSSProperties>({
        maxWidth: '200px', maxHeight: '200px', position: 'absolute', marginLeft: '145px', marginTop: '170px'
    });

    // MODAL EDIT CALLBACKS
    const saveData = useCallback(async (data: any) => {
        save(STRAINS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback(async (data: any) => {
        deleteDoc(STRAINS, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback(async (undoData: any) => {
        Object.assign(data, undoData);
        save(STRAINS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchEvent = useCallback((event: { detail: string }) => {
        searchBy?.(getStrainsRegEx, STRAINS, 'VARIETY_NAME', event.detail);
    }, [searchBy]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        if (isNotUndefined(event.detail.STRAIN_ID)) {
            setTitle(event.detail.VARIETY_NAME + " (" + event.detail.STRAIN_ID + ")");
            setIsShow(false);
            setData(event.detail);
            setIsShow(true);
        } else {
            searchByDoc?.(getObjectByDoc, STRAINS, event.detail, true, 'STRAIN_ID');
        }
    }, [setTitle, setIsShow, setData, searchByDoc]);

    const searchStrainByConcentrationEvent = useCallback(async (event: { detail: any }) => {
        getStrainIdFromConcentration(event.detail)
            .then(_data => {
                if (isNotUndefined(_data[0])) {
                    setTitle(_data[0].VARIETY_NAME + " (" + _data[0].STRAIN_ID + ")");
                    setIsShow(false);
                    setData(_data[0]);
                    setIsShow(true);
                    Events.trigger(EventsType.SEARCH_BY_STRAIN, _data[0]);
                }
            })
            .catch(error => {
                setError(error);
                setIsShow(true);
            });
    }, [setTitle, setIsShow, setData, setError]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);

        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_STRAIN, searchByCompoundEvent, UUID);
            Events.on(EventsType.SEARCH_STRAIN_BY_CONCENTRATION, searchStrainByConcentrationEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_STRAIN, UUID);
            Events.off(EventsType.SEARCH_STRAIN_BY_CONCENTRATION, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByCompoundEvent, searchStrainByConcentrationEvent]);

    // RENDER FUNCTIONS
    const parseTotalKeys = useCallback((data: any): { NAME: string; VALUE: any }[] => {
        const totalObjects: { NAME: string; VALUE: string }[] = [];

        for (const key in data) {
            if (key.startsWith("TOTAL_")) {
                totalObjects.push({ NAME: key, VALUE: parseFloat(data[key]).toFixed(4) });
            }
        }
        return totalObjects.sort((a, b) => parseFloat(b.VALUE) - parseFloat(a.VALUE)); // Sort by VALUE descending
    }, []);

    // Note: The original useEffect with styleParam and setTimeout(handleButtonClick) was removed as handleButtonClick wasn’t defined.
    // If you need specific behavior here, please provide more context.

    return (
        <>
            <ChartPie data={parseTotalKeys(data)} styleParam={styleParam} />
            {renderFields(StrainObject, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={StrainObject}
                    />
                    <DisplayDeleteModal
                        deleteDoc={deleteData}
                        data={data}
                        schema={StrainObject}
                    />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const StrainCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_StrainCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(StrainCard);
