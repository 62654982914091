import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    STABILITY,
    getObjectsByRegEx,
    save,
    deleteDoc
} from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { isNotEmpty } from "../../../utils/string";
import { useRoles } from "../../../App.Context";
import { Stability } from "./types";

const _StabilityCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, searchBy, searchByDoc, isActive }) => {
    const UUID = '_StabilityCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback(async (data: any) => {
        save(STABILITY, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback(async (data: any) => {
        deleteDoc(STABILITY, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback(async (undoData: any) => {
        Object.assign(data, undoData);
        save(STABILITY, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchEvent = useCallback((event: { detail: string }) => {
        if (event.detail.length > 0 && data.INCHIKEY !== event.detail) {
            searchBy?.(getObjectsByRegEx, STABILITY,
                'STUDY_TITLE,TREATMENT,TREATMENT_DESCRIPTION,ANALYSIS_TYPE,MEASURE_TYPE,SAMPLING_DESCRIPTION,METHOD_DESCRIPTION,INCHIKEY', event.detail);
        }
    }, [searchBy, data.INCHIKEY]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string }) => {
        if (event.detail.length > 0 && data.INCHIKEY !== event.detail) {
            searchBy?.(getObjectsByRegEx, STABILITY, 'INCHIKEY', event.detail);
        }
    }, [searchBy, data.INCHIKEY]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        setData(event.detail);
        setIsShow(true);
    }, [setData, setIsShow]);

    // EVENTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_BY_STABILITY, searchByCompoundEvent, UUID);
        }
        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_STABILITY, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByInchiKeyEvent, searchByCompoundEvent]);

    // RENDERS
    return (
        <p>
            {renderFields(Stability, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={Stability}
                        excludeListParameters={["COMPOUND_NAME"]} />
                    <DisplayDeleteModal deleteDoc={deleteData}
                                        data={data}
                                        schema={Stability} />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </p>
    );
};

const StabilityCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_StabilityCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(StabilityCard);
