import React, { useState, useEffect } from 'react';
import { isNotUndefined } from "../../../utils/string";
import { IconButton, Box, Button, Tabs, Tab, DialogContent, Dialog } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import { iBasket } from "./types";
import ChartPieProduct from "../../graphics/ChartPie.Products";
import ChartPieTerpenesConcentrations from "../../graphics/ChartPieTerepenesConcentrations"; // Fixed typo in import name
import ChartPiePKIndices from "../../graphics/ChartPie.PKIndices";
import ChartBarProducts from "../../graphics/ChartBar.Products";
import ChartBarTerpenes from "../../graphics/ChartBar.Terepenes";

interface DynamicModalProps<T> {
    basket: iBasket;
    isOpen: boolean;
    handleCloseModal: () => void;
}

const BasketGraphics = <T extends Record<string, any>>({ basket, isOpen, handleCloseModal }: DynamicModalProps<T>) => {
    const [basketValues, setBasketValues] = useState<iBasket>(basket);
    const [productChartType, setProductChartType] = useState<'pie' | 'bar'>('bar');
    const [terpeneChartType, setTerpeneChartType] = useState<'pie' | 'bar'>('bar');
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        setBasketValues(basket);
    }, [basket]);

    if (!isOpen) {
        return null;
    }

    const handleProductChartTypeChange = (type: 'pie' | 'bar') => {
        setProductChartType(type);
    };

    const handleTerpeneChartTypeChange = (type: 'pie' | 'bar') => {
        setTerpeneChartType(type);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseModal}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    width: '80vw',
                    height: '90vh',
                    maxWidth: 'none',
                    maxHeight: 'none',
                }
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basket graphics tabs">
                    <Tab label="Products" />
                    <Tab label="Terpenes Concentrations" />
                    <Tab label="PK Indices" />
                </Tabs>
                <IconButton onClick={handleCloseModal} color="primary" sx={{ ml: 'auto' }}>
                    <ClearIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ padding: '10px' }}>
                {isNotUndefined(basketValues) && (
                    <Box sx={{ mt: 2 }}>
                        {tabValue === 0 && (
                            <Box>
                                <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                                    <Button
                                        variant={productChartType === 'pie' ? 'contained' : 'outlined'}
                                        color={productChartType === 'pie' ? 'primary' : 'inherit'}
                                        onClick={() => handleProductChartTypeChange('pie')}
                                        startIcon={<PieChartIcon />}
                                        size="small"
                                    >
                                        Pie Chart
                                    </Button>
                                    <Button
                                        variant={productChartType === 'bar' ? 'contained' : 'outlined'}
                                        color={productChartType === 'bar' ? 'primary' : 'inherit'}
                                        onClick={() => handleProductChartTypeChange('bar')}
                                        startIcon={<BarChartIcon />}
                                        size="small"
                                    >
                                        Bar Chart
                                    </Button>
                                </Box>
                                {productChartType === 'pie' ? (
                                    <ChartPieProduct data={basketValues} />
                                ) : (
                                    <ChartBarProducts data={basketValues.items} />
                                )}
                            </Box>
                        )}
                        {tabValue === 1 && (
                            <Box>
                                <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                                    <Button
                                        variant={terpeneChartType === 'pie' ? 'contained' : 'outlined'}
                                        color={terpeneChartType === 'pie' ? 'primary' : 'inherit'}
                                        onClick={() => handleTerpeneChartTypeChange('pie')}
                                        startIcon={<PieChartIcon />}
                                        size="small"
                                    >
                                        Pie Chart
                                    </Button>
                                    <Button
                                        variant={terpeneChartType === 'bar' ? 'contained' : 'outlined'}
                                        color={terpeneChartType === 'bar' ? 'primary' : 'inherit'}
                                        onClick={() => handleTerpeneChartTypeChange('bar')}
                                        startIcon={<BarChartIcon />}
                                        size="small"
                                    >
                                        Bar Chart
                                    </Button>
                                </Box>
                                {terpeneChartType === 'pie' ? (
                                    <ChartPieTerpenesConcentrations data={basketValues} />
                                ) : (
                                    <ChartBarTerpenes data={basketValues.items} />
                                )}
                            </Box>
                        )}
                        {tabValue === 2 && <ChartPiePKIndices data={basketValues} />}
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BasketGraphics;
