import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, IconButton } from "@mui/material";
import { FileDownload as FileDownloadIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { getByFilters, getObjectsByRegEx, PRODUCT_DEGRADATION_COMPOUNDS } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import Button from "@mui/material/Button";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { ProductDegradationCompounds } from "./types";

const _ProductsDegradationCompoundsTable: React.FC<iBaseInnerCardProps> = ({ data,
                                                                               setColumnFilters, columnFilters,
                                                                               isActive,
                                                                               searchListBy, searchListByFilters }) => {
    const UUID = '_ProductsDegradationCompoundsTable';

    const columnNames = Object.keys(ProductDegradationCompounds);
    const columnsToView = ['INCHIKEY', 'COMPOUND_NAME', 'TRANSFORMATION_NAME', 'TRANSFORMATION_GROUP', 'CONDITION_TRIGGERS'];

    const eventMapping: CellEventMapping = {
        'INCHIKEY': EventsType.SEARCH_BY_INCHIKEY
    };

    const columns = getColumns(ProductDegradationCompounds, 0, createCellsWithEvents(eventMapping));
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    // Commented out as per original code, preserved for reference
    // const _search = useCallback((text: string) => {
    //     if (text.length > 0) {
    //         searchListBy?.(getObjectsByRegEx, PRODUCT_DEGRADATION_COMPOUNDS,
    //             'NAME,COMPOUND_NAME,PARENT_ID,PARENT_2_ID,' +
    //             'TRANSFORMATION_NAME,TRANSFORMATION_GROUP,TRANSFORMATION_GROUP,CONDITION_TRIGGERS,' +
    //             'TRANSFORMATION_DESCRIPTION,TRANSFORMATION_REFERENCES,TRANSFORMATION_COMMENTS', text);
    //     }
    // }, [searchListBy]);
    //
    // const searchEvent = useCallback(async (event: { detail: string }) => {
    //     if (isActive) {
    //         _search(event.detail);
    //     }
    // }, [isActive, _search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (isActive && event.detail.component === PRODUCT_DEGRADATION_COMPOUNDS) {
            searchListByFilters?.(getByFilters, PRODUCT_DEGRADATION_COMPOUNDS, event.detail.filters, 'wizard result');
        }
    }, [isActive, searchListByFilters]);

    const searchByProductDegradationID = useCallback(async (event: { detail: string }) => {
        if (isActive) {
            searchListBy?.(getObjectsByRegEx, PRODUCT_DEGRADATION_COMPOUNDS, 'PRODUCT_ID', event.detail);
        }
    }, [isActive, searchListBy]);

    // DATA TRANSFORMATION
    interface Compound {
        INCHIKEY: string;
        PARENT_ID?: string;
        PARENT_2_ID?: string;
        subRows?: Compound[];
    }

    const transformCompoundData = useCallback((data: Compound[]): Compound[] => {
        const map: Record<string, Compound> = {};
        const result: Compound[] = [];

        data.forEach(compound => {
            map[compound.INCHIKEY] = compound;
        });

        data.forEach(compound => {
            if (!compound.PARENT_ID && !compound.PARENT_2_ID) {
                result.push(compound);
            } else {
                const parentId = compound.PARENT_ID || compound.PARENT_2_ID!;
                const parent = map[parentId];
                if (parent) {
                    parent.subRows = parent.subRows || [];
                    parent.subRows.push(compound);
                }
            }
        });

        return result;
    }, []);

    const transformedData = useMemo(() => transformCompoundData(data), [data, transformCompoundData]);

    // EVENTS
    useEffect(() => {
        // Events.on(EventsType.SEARCH, searchEvent, UUID); // Commented out as per original
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, searchByProductDegradationID, UUID);

        return () => {
            // Events.off(EventsType.SEARCH, UUID); // Commented out as per original
            Events.off(EventsType.SEARCH_WIZARD, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, UUID);
        };
    }, [isActive, searchWizard, searchByProductDegradationID]); // Removed searchEvent from dependencies since it’s commented out

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={transformedData ?? []}
            enableExpanding={true}
            enableExpandAll={true}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                pagination: { pageSize: 30, pageIndex: 0 },
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'COMPOUND_NAME',
                    'INCHIKEY',
                    'PARENT_ID',
                    'PARENT_2_ID',
                    'INTERMEDIATE_TYPES',
                    'PATHWAY_SCORES',
                    'TRANSFORMATION_NAME',
                    'TRANSFORMATION_GROUP',
                    'CONDITION_TRIGGERS',
                    'EXACT_MASS',
                    'AVERAGE_MASS',
                    'MASS_DIFFERENCE',
                    'FORMULA',
                    'FORMULA_GAIN',
                    'FORMULA_LOSS'
                ],
            }}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_COMPOUND, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "ProductsDegradationCompounds")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "ProductsDegradationCompounds")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const ProductsDegradationCompoundsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ProductsDegradationCompoundsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductsDegradationCompoundsTable);
