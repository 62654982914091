import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { CANNABIS_COMPOUNDS, getObjectsByRegEx, getObjectByDoc, save, deleteDoc } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty, isValidIchiKey } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { CannabisCompounds } from "./types";

const _CannabinoidCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, isActive, searchBy, searchByDoc }) => {
    const UUID = '_CannabinoidCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(CANNABIS_COMPOUNDS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(CANNABIS_COMPOUNDS, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(CANNABIS_COMPOUNDS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchEvent = useCallback((event: { detail: string }) => {
        if (isValidIchiKey(event.detail)) {
            searchBy?.(getObjectsByRegEx, CANNABIS_COMPOUNDS, 'INCHIKEY', event.detail);
        } else {
            searchBy?.(getObjectsByRegEx, CANNABIS_COMPOUNDS, 'COMPOUND_NAME,FAMILY_TYPE_NAME,FAMILY_TYPE_CODE', event.detail);
        }
    }, [searchBy]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, CANNABIS_COMPOUNDS, 'INCHIKEY', event.detail);
    }, [searchBy]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, CANNABIS_COMPOUNDS, event.detail, true, 'COMPOUND_NAME');
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CANNABIS_COMPOUND, searchByCompoundEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_CANNABIS_COMPOUND, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByInchiKeyEvent, searchByCompoundEvent]);

    // RENDERS
    return (
        <p>
            {renderFields(CannabisCompounds, data)}

            <AuditModal id={data._id} undo={undo} />

            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={CannabisCompounds}
                        excludeListParameters={["COMPOUND_NAME"]}
                    />
                    <DisplayDeleteModal
                        deleteDoc={deleteData}
                        data={data}
                        schema={CannabisCompounds}
                    />
                </>
            )}
        </p>
    );
};

const CannabinoidCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_CannabinoidCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(CannabinoidCard);
