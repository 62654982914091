import React, { useCallback, useEffect, useState } from "react";
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import Events from "../../../events";
import { EventsType } from "../../../events-types";
import { Collapse, Divider, List, ListItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getDataByField } from "../../../api/Pubchem";
import { isNotUndefined } from "../../../utils/string";
import PubchemId from "./PubchemId";

const _PubchemCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, isActive }) => {
    const UUID = '_PubchemCard';
    const [isOpen, setIsOpen] = useState(false);
    let debounceTimeout: number = 0;

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    // SEARCH FUNCTIONS
    const searchBy = useCallback((key: string, value: string, field: string) => {
        setIsShow(false);
        getDataByField(key, value, field)
            .then(response => {
                if (isNotUndefined(response.data) && response.data.length > 0) {
                    setTitle(value);
                    setData(response.data[0]);
                    setIsShow(true);
                } else if (response.error) {
                    setError(response.error);
                    setIsShow(true);
                }
            })
            .catch(error => {
                setError(error);
                setIsShow(true);
            });
    }, [setIsShow, setTitle, setData, setError]);

    const searchByPubChemCompoundEvent = useCallback((event: { detail: any }) => {
        setData(event.detail);
        setTitle(event.detail['cmpdname']);
        setIsShow(true);
        clearTimeout(debounceTimeout);
    }, [setData, setTitle, setIsShow]);

    const searchByPubchemCIDEvent = useCallback((event: { detail: any }) => {
        clearTimeout(debounceTimeout); // Cancel any existing debounce timeout
        debounceTimeout = window.setTimeout(() => {
            if (data.cid !== event.detail && String(event.detail) !== '-') {
                searchBy('compound', event.detail, 'cid');
            }
            debounceTimeout = 0; // Reset debounce timeout after execution
        }, 1000);
    }, [data.cid, searchBy]);

    const searchByInchiKeyEvent = useCallback((event: { detail: any }) => {
        searchBy('compound', event.detail, '*');
    }, [searchBy]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEND_PUBCHEM_COMPOUND, searchByPubChemCompoundEvent, UUID);
            Events.on(EventsType.SEARCH_BY_PUBCHEM_CID, searchByPubchemCIDEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        }
        return () => {
            Events.off(EventsType.SEND_PUBCHEM_COMPOUND, UUID);
            Events.off(EventsType.SEARCH_BY_PUBCHEM_CID, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
        };
    }, [isActive, searchByPubChemCompoundEvent, searchByPubchemCIDEvent, searchByInchiKeyEvent]);

    // RENDER
    return (
        <>
            <PubchemId data={data} />
            <b>IUPAC NAME</b>: {data['iupacname']}<br />
            <List component='nav' aria-labelledby='nested-list-subheader'>
                <ListItem onClick={handleClick} style={{ background: 'cyan' }}>
                    SYNONYMS
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={isOpen}
                    timeout='auto'
                    unmountOnExit
                >
                    <List component='li' disablePadding>
                        {(data['cmpdsynonym'] || '').split('|').map((s: string) => (
                            <ListItem key={s}>
                                {s}
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
                <Divider />
            </List>
            <br />
            <b>SMILES</b>: {data['canonicalsmiles']}<br />
            <b>INCHI</b>: {data['inchi']}<br />
            <b>INCHIKEY</b>: {data['inchikey']}<br />
            <b>MOLECULA FORMULA </b>: {data['mf']}<br />
            <b>EXACT MASS</b>: {data['exactmass']}<br />
            <b>MONO ISOTOPIC MASS</b>: {data['monoisotopicmass']}<br />
            <b> MW </b>: {data['mw']}<br />
            <b>XLOGP </b>: {data['xlogp']}<br />
            <b>POLAR AREA </b>: {data['polararea']}<br />
            <b>ROTBONDS </b>: {data['rotbonds']}<br />
            <b>ANNOTATION: </b>{data['annotation']}<br />
        </>
    );
};

const PubchemCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PubchemCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PubchemCard);
