import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    getObjectByDoc,
    getStudiesRegEx,
    save,
    deleteDoc,
    CBD_PK_INDICES,
} from '../../../api/Studies';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty, isNotUndefined } from "../../../utils/string";
import FormEditModal from "../../common/FormEditModal";
import { renderFields } from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { PK_Indices } from "./types";

const _PKIndicesCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                           setData, data, isActive,
                                                           setTitle, setSubtitle, searchByDoc }) => {
    const UUID = '_PKIndicesCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback(async (data: any) => {
        save(CBD_PK_INDICES, data)
            .then((res) => {
                setData(res);
            })
            .catch(setError);
    }, [setData, setError]);

    const deleteData = useCallback(async (data: any) => {
        deleteDoc(CBD_PK_INDICES, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback(async (undoData: any) => {
        Object.assign(data, undoData);
        save(CBD_PK_INDICES, data)
            .then((res) => {
                setData(res);
            })
            .catch(setError);
    }, [data, setData, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            setTitle(text);
            getStudiesRegEx(CBD_PK_INDICES, '', 'PRODUCT,TITLE', text)
                .then(response => {
                    if (isNotUndefined(response) && response.length === 1) {
                        setData(response[0]);
                        setIsShow(true);
                    }
                })
                .catch(error => {
                    setError(error);
                    setIsShow(true);
                });
        }
    }, [setTitle, setData, setIsShow, setError]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchByStudyPK = useCallback((event: { detail: any }) => {
        setTitle(event.detail.TITLE);
        setIsShow(false);
        setData(event.detail);
        setIsShow(true);
    }, [setTitle, setIsShow, setData]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, CBD_PK_INDICES, event.detail, true, 'TITLE');
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);

        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_STUDY_PK_INDICES, searchByStudyPK, UUID);
            Events.on(EventsType.SEARCH_BY_STUDY, searchByCompoundEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_STUDY_PK_INDICES, UUID);
            Events.off(EventsType.SEARCH_BY_STUDY, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByStudyPK, searchByCompoundEvent]);

    // RENDERS
    return (
        <p>
            {renderFields(PK_Indices, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={PK_Indices}
                    />
                    <DisplayDeleteModal
                        deleteDoc={deleteData}
                        data={data}
                        schema={PK_Indices}
                    />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </p>
    );
};

const PKIndicesCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PKIndicesCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PKIndicesCard);
