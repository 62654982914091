import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { MARKET_CBD, getByFilters, getObjectsByRegEx } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from '../../types';
import Button from "@mui/material/Button";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { Market_CBD } from "./types";

const _MarketCBDTable: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                            setData, data, isActive,
                                                            setColumnFilters, columnFilters,
                                                            searchListByFilters,
                                                            setTitle, setSubtitle, searchListBy }) => {
    const UUID = 'MarketCBDTable';

    const columnNames = Object.keys(Market_CBD);
    const columnsToView = ['DRUG_NAME', 'ACTIVE_INGREDIENT'];
    const columns = getColumns(Market_CBD);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        searchListBy?.(getObjectsByRegEx, MARKET_CBD, 'DRUG_NAME,ACTIVE_INGREDIENT,THERAPEUTICAL_INDICATION', text);
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === MARKET_CBD) {
            searchListByFilters?.(getByFilters, MARKET_CBD, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        }

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive, searchEvent, searchWizard]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'DRUG_NAME', 'ACTIVE_INGREDIENT'
                ],
            }}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>THERAPEUTICAL INDICATION: </b> {row.original.THERAPEUTICAL_INDICATION}</Typography>
                </Box>
            )}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_CBD_DRUG, row.original);
                            }}>
                        {row.original.DRUG_ID || 'N/A'}
                    </Button>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "CBD Market")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "CBD Market")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const MarketCBDTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MarketCBDTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(MarketCBDTable);
