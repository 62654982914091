import React, { useCallback, useEffect } from 'react';
import { Box, IconButton } from "@mui/material";
import { Visibility as VisibilityIcon, FileDownload as FileDownloadIcon } from '@mui/icons-material';
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { getStudiesRegEx, getByFilters, STUDIES_CBD, SC_PK } from '../../../api/Studies';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import Button from "@mui/material/Button";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { isNotUndefined } from "../../../utils/string";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { PK } from "./types";

const _PKTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                         setData, data,
                                                         setColumnFilters, columnFilters,
                                                         setTitle, setSubtitle,
                                                         searchListByFilters,
                                                         isActive }) => {
    const UUID = '_PKTableCard';

    const columnNames = Object.keys(PK);
    const columnsToView = ['PRODUCT', 'STUDY_TYPE', 'DOSE', 'FORMULATION', 'DELIVERY', 'TMAX_CBD'];
    const columns = getColumns(PK);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        setIsShow(false);
        getStudiesRegEx(STUDIES_CBD, SC_PK, 'PRODUCT,TITLE', text)
            .then((response) => {
                if (isNotUndefined(response) && response.length > 1) {
                    setTitle(text);
                    setData(response);
                    setIsShow(true);
                }
            })
            .catch(error => {
                setError(error);
                setIsShow(true);
            });
    }, [setIsShow, setTitle, setData, setError]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === SC_PK) {
            event.detail.filters.push({
                property: 'STUDY_CLASS',
                value: SC_PK,
                operator: '=='
            });
            searchListByFilters?.(getByFilters, STUDIES_CBD, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive, searchEvent, searchWizard]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <p>
            <MaterialReactTable
                columns={columns}
                data={data ?? []}
                enableRowSelection
                enableColumnOrdering
                enableGlobalFilter={false}
                enableDensityToggle={true}
                initialState={{
                    columnVisibility: _initial_columns_state,
                    density: 'compact',
                }}
                onColumnFiltersChange={handleFilterFnChange}
                state={{ columnFilters }}
                enableRowActions={true}
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_STUDY_PK, row.original);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Box>
                )}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            onClick={() => exportToExcel(data, "PKs")}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                        <Button
                            disabled={
                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            }
                            onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "PKs")}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Selected Rows
                        </Button>
                    </Box>
                )}
            />
        </p>
    );
};

const PKTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PKTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PKTableCard);
