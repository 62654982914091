import React, {useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb';

import 'react-tabs/style/react-tabs.css';
import './css/App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';

import './css/style.css';
import './css/Card.css';


import CompoundCard from './components/tabs/compounds/Compound';
import CompoundsTable from './components/tabs/compounds/CompoundsTable';
import CannabinoidTable from "./components/tabs/compounds/CannabinoidsTable";
import CannabinoidCard from "./components/tabs/compounds/Cannabinoid";
import TherapeuticIndicatorsTableCard from "./components/tabs/./disorders_diseases/TherapeuticalIndicatorsTable";
import PKTableCard from "./components/tabs/pharmacology/PKTable";
import StrainsTable from "./components/tabs/plants_varieties/StrainsTable";
import TherapeuticalIndicatorCard from "./components/tabs/./disorders_diseases/TherapeuticalIndicator";
import PKCard from "./components/tabs/pharmacology/PK";
import StrainCard from "./components/tabs/plants_varieties/Strain";
import PubChemTableCard from "./components/tabs/compounds/PubchemTable";
import PubChemListCard from "./components/tabs/compounds/PubchemList";
import PubchemCard from "./components/tabs/compounds/Pubchem";
import MetabolitesTableCard from "./components/tabs/pharmacology/MetabolitesTable";
import PubchemClinicalTrialsTableCard from "./components/tabs/./disorders_diseases/PubchemClinicalTrials";
import ReferenceCard from "./components/tabs/references/Reference";
import StrainsCompoundsConcentrations from "./components/tabs/plants_varieties/StrainCompoundsConcentrations";
import ClinicalTrialsTableCard from "./components/tabs/./disorders_diseases/ClinicalTrialsTable";
import ClinicalTrialCard from "./components/tabs/./disorders_diseases/ClinicalTrial";
import CannabisDBCard from "./components/tabs/compounds/CannabisDB";
import AllBaskets from "./components/tabs/basket/AllBaskets";


import ChangeLog from "./components/tabs/loggers_audits/changeLog";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MarkIcon from "./components/graphics/MarkIcon";
import {Button} from "@mui/material";
import Events from "./events";
import {EventsType} from "./events-types";
import {ContextMessage} from "./components/ContextMessage";
import MetaboliteCard from "./components/tabs/pharmacology/Metabolite";
import CreateData from "./components/AddNewData";
import MarketCBDCard from "./components/tabs/products/MarketCBD";
import MarketCBDTable from "./components/tabs/products/MarketCBDTable";
import AuditTrailCard from "./components/tabs/loggers_audits/AuditTrail";
import StadisticsCard from "./components/tabs/loggers_audits/Stadistics";
import UploadsCard from "./components/tabs/loggers_audits/Uploads";
import WizardSearch from "./components/common/wizards/WizardSearch";

import {
    AROMA,
    COMPOUND,
    MARKET_CBD,
    REFERENCES,
    PHARMACO_DYNAMICS,
    PHYSICAL_CHARACTERIZATION_CBD, MUTAGENICITY, STABILITY, CANNABIS_COMPOUNDS
} from "./api/Compounds";
import {STRAINS} from "./api/Strains";
import {CBD_PK_INDICES, SC_PK, SC_THERAPEUTIC_INDICATIONS, STUDIES_METABOLITES} from "./api/Studies";
import {
    PRODUCTS_ANALYSIS_METHODS,
    PRODUCT_ANALYSIS_VALUES,
    PRODUCTS_ANALYSIS_REPORTS,
    PRODUCT_DEGRADATION, PRODUCT_DEGRADATION_COMPOUNDS
} from "./api/Products"
import {StorageProvider} from "./App.Context";
import {getCurrentUserNameAndRoles} from "./services/Cognito";
import ChemSpiderTableCard from "./components/tabs/compounds/ChemSpiderTable";
import ChemSpiderCard from "./components/tabs/compounds/ChemSpider";
import PKIndicesTableCard from "./components/tabs/pharmacology/PKIndicesTable";
import PKIndicesCard from "./components/tabs/pharmacology/PKIndices";
import PhysicalCharacterizationCBDTable from "./components/tabs/products/PhysicalCharacterizationCBDTable";
import PhysicalCharacterizationCard from "./components/tabs/products/PhysicalCharacterizationCBD";
import PharmacoDynamicsTable from "./components/tabs/pharmacology/PharmacoDynamicsTable";
import PharmacoDynamicsCard from "./components/tabs/pharmacology/PharmacoDynamics";
import AromaTable from "./components/tabs/compounds/AromaTable";
import AromaCard from "./components/tabs/compounds/Aroma";
import ProductAnalisysValueCard from "./components/tabs/products/ProductAnalysisValue";
import ProductAnalisysMethodCard from "./components/tabs/products/ProductsAnalysisMethod"
import ProductAnalysisValuesTable from "./components/tabs/products/ProductAnalysisValuesTable";


import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Tooltip} from "@mui/material";
import ProductAnalisysReportCard from "./components/tabs/products/ProductAnalysisReport";
import ProductsAnalysisReportsTable from "./components/tabs/products/ProductAnalysisReportTable";
import ProductsAnalysisMethodTable from './components/tabs/products/ProductsAnalysisMethodTable';

import ProductsDegradationTable from "./components/tabs/quality_safety/ProductsDegradationTable";
import ProductDegradationCard from "./components/tabs/quality_safety/ProductDegradation";
import ProductDegradationCompoundsTable from "./components/tabs/quality_safety/ProductDegradationCompoundsTable";
import ProductDegradationCompoundCard from "./components/tabs/quality_safety/ProductDegradationCompound";
import MutagenicityCard from "./components/tabs/toxicity/Mutagenicity";
import MutagenicityTableCard from "./components/tabs/toxicity/MutagenicityTable";
import {Strain} from "./components/tabs/plants_varieties/types";
import {
    Aroma,
    CannabisCompounds,
    Compound,
} from "./components/tabs/compounds/types";
import {Metabolite, PharmacoDynamics, PK, PK_Indices} from "./components/tabs/pharmacology/types";
import {Mutagenicity} from "./components/tabs/toxicity/types";
import {TherapeuticIndicator} from "./components/tabs/./disorders_diseases/types";
import {ProductDegradation, ProductDegradationCompounds, Stability} from "./components/tabs/quality_safety/types";
import {
    Market_CBD,
    Physical_characterization_CBD, ProductAnalysisMethod,
    ProductAnalysisReport,
    ProductAnalysisValue
} from "./components/tabs/products/types";
import {Reference} from "./components/tabs/references/types";
import StabilityTableCard from "./components/tabs/quality_safety/StabilityTable";
import StabilityCard from "./components/tabs/quality_safety/Stability";
import ReferenceTableCard from "./components/tabs/references/ReferenceTable";

function App() {

    const [ roles, setRoles ] = useState<string[]>([]);

    const [isReadOnly, setIsReadOnly] = useState(true);
    const [showChangeLog, setShowChangeLog] = useState(true);
    const handleHideChangeLog = () => {
        setShowChangeLog(false);
    };

    const listsOfObjects = {
        [`${AROMA}`]: Aroma,
        [`${CANNABIS_COMPOUNDS}`]: CannabisCompounds,
        [`${COMPOUND}`]: Compound,
        [`${REFERENCES}`]: Reference,
        [`${STRAINS}`]: Strain,
        [`${SC_THERAPEUTIC_INDICATIONS}`]: TherapeuticIndicator,
        [`${MARKET_CBD}`]: Market_CBD,
        [`${PHYSICAL_CHARACTERIZATION_CBD}`]: Physical_characterization_CBD,
        [`${STUDIES_METABOLITES}`]: Metabolite,
        [`${SC_PK}`]: PK,
        [`${CBD_PK_INDICES}`]: PK_Indices,
        [`${PHARMACO_DYNAMICS}`]: PharmacoDynamics,
        [`${PRODUCTS_ANALYSIS_METHODS}`]: ProductAnalysisMethod,
        [`${PRODUCT_ANALYSIS_VALUES}`]: ProductAnalysisValue,
        [`${PRODUCTS_ANALYSIS_REPORTS}`]: ProductAnalysisReport,
        [`${PRODUCT_DEGRADATION}`]: ProductDegradation,
        [`${PRODUCT_DEGRADATION_COMPOUNDS}`]: ProductDegradationCompounds,
        [`${MUTAGENICITY}`]: Mutagenicity,
        [`${STABILITY}`]: Stability,

    };

    function clearAll() {
        Events.trigger(EventsType.CLEAR_ALL_DATA, []);
    }

    // forcing the workflow of the user identification and roles
    const fetchCurrentUser = async () => {
        try {
            const {user, roles } = await getCurrentUserNameAndRoles();
            console.log('roles', roles);
            setRoles(roles);
            setIsReadOnly(roles.includes("ReadOnly"));
        } catch (error) {
            console.log('Error setting current user:', error);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

  return (
      <StorageProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <div className="App">

            <Navbar />

            <ContextMessage>
                <Tabs forceRenderTabPanel={true}>
                    <TabList style={{textAlign: "left"}}>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Plants / Varieties
                                <MarkIcon width={'10px'} name={'plants_varieties'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Compounds
                                <MarkIcon width={'10px'} name={'compounds'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Pharmacology
                                 <MarkIcon width={'10px'} name={'pharmacology'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Toxicity
                                <MarkIcon width={'10px'} name={'toxicity'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Disorders / Diseases
                                <MarkIcon width={'10px'} name={'disorders_diceases'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Quality & Safety
                                <MarkIcon width={'10px'} name={'quality_safety'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Products
                                <MarkIcon width={'10px'} name={'products'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Regulations
                                <MarkIcon width={'10px'} name={'regulations'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Patents
                                <MarkIcon width={'10px'} name={'patents'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                References
                                <MarkIcon width={'10px'} name={'references'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Baskets
                                <MarkIcon width={'10px'} name={'baskets'}/>
                            </span></Tab>
                        <Tab>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Loggers / Audit Trail
                                <MarkIcon width={'10px'} name={'loggers'}/>
                            </span></Tab>
                    </TabList>

                    {/* Plants/Varieties */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <StrainsTable       header={'Varieties'} eventGroupToTrigger={'plants_varieties'} />
                            </div>
                            <div className="col-3 col-sm-3">
                                <StrainCard         header={'Variety'} eventGroupToTrigger={'plants_varieties'}/>
                                <StrainsCompoundsConcentrations header={'Concentration of terpenes in batch:'} eventGroupToTrigger={'plants_varieties'}/>
                            </div>
                        </div>
                    </TabPanel>

                    {/* Compounds */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <CompoundsTable     header={'Compounds'}   eventGroupToTrigger={'compounds'}/>
                                <CannabinoidTable   header={'Cannabis Compounds'}  eventGroupToTrigger={'compounds'}/>
                                <AromaTable         header={'Aroma Table'}  eventGroupToTrigger={'compounds'}/>
                                <PubChemListCard    header={'PUBCHEM - Component/Substances List'} eventGroupToTrigger={'compounds'} />
                                <PubChemTableCard   header={'PUBCHEM - Properties'} storageValue={"Properties"} eventGroupToTrigger={'compounds'} />
                                <ChemSpiderTableCard header={'ChemSpider - Components'} eventGroupToTrigger={'compounds'} />

                            </div>
                            <div className="col-3 col-sm-3">
                                <CompoundCard        header={'Compound'}  eventGroupToTrigger={'compounds'}   />
                                <CannabinoidCard     header={'Cannabis Compound'} eventGroupToTrigger={'compounds'}  />
                                <AromaCard          header={'Aroma'}  eventGroupToTrigger={'compounds'}  />
                                <CannabisDBCard     header={'Cannabis DB'}  eventGroupToTrigger={'compounds'}/>
                                <PubchemCard        header={'PUBCHEM Properties'}  eventGroupToTrigger={'compounds'}/>
                                <ChemSpiderCard     header={'ChemSpider Properties'}  eventGroupToTrigger={'compounds'}/>
                            </div>
                        </div>
                    </TabPanel>

                    {/* Pharmacology */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <MetabolitesTableCard header={'Metabolites'}  eventGroupToTrigger={'pharmacology'}/>
                                <PharmacoDynamicsTable header={'Pharmaco Dynamics Table'}  eventGroupToTrigger={'pharmacology'}/>
                                <PKTableCard                    header={'PK'}              eventGroupToTrigger={'pharmacology'}/>
                                <PKIndicesTableCard             header={'PK Indices'}      eventGroupToTrigger={'pharmacology'}/>

                            </div>
                            <div className="col-3 col-sm-3">
                                <MetaboliteCard     header={'Metabolite'} eventGroupToTrigger={'pharmacology'}  />
                                <PharmacoDynamicsCard header={'Pharmaco Dynamics'}  eventGroupToTrigger={'pharmacology'}  />
                                <PKCard                     header={'PK'}             eventGroupToTrigger={'pharmacology'} />
                                <PKIndicesCard              header={'PK Indices'}     eventGroupToTrigger={'pharmacology'} />

                            </div>
                        </div>
                    </TabPanel>

                    {/* Toxicity */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <MutagenicityTableCard   header={'Mutagenicities'} eventGroupToTrigger={'toxicity'} />
                                <PubChemTableCard   header={'PUBCHEM - Toxicity'} storageValue={"toxicity"} eventGroupToTrigger={'toxicity'} />
                            </div>
                            <div className="col-3 col-sm-3">
                                <MutagenicityCard           header={'In Silico Mutagenicity'} eventGroupToTrigger={'toxicity'} />
                            </div>
                        </div>
                    </TabPanel>

                    {/* Disorders/Diseases */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <TherapeuticIndicatorsTableCard header={'Therapeutic Indications'}  eventGroupToTrigger={'disorders_diceases'}/>
                                <PubchemClinicalTrialsTableCard header={'PUBCHEM - Clinical Trials'}  eventGroupToTrigger={'disorders_diceases'}/>
                                <ClinicalTrialsTableCard  header={'Clinical Trials GOV'} eventGroupToTrigger={'disorders_diceases'}/>
                                <PubChemTableCard  header={'PUBCHEM - Disorders + Diceases'}
                                                   storageValue={"disorders_diceases"} eventGroupToTrigger={'disorders_diceases'} />
                            </div>
                            <div className="col-3 col-sm-3">
                                <TherapeuticalIndicatorCard header={'Threapeutical Indications'} eventGroupToTrigger={'disorders_diceases'} />
                                <ClinicalTrialCard          header={'Clinical Trial'} eventGroupToTrigger={'disorders_diceases'} />
                            </div>
                        </div>
                    </TabPanel>

                    {/* Quality & Safety */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <ProductsDegradationTable header={'Products Degradation'} eventGroupToTrigger={'quality_safety'} />
                                <ProductDegradationCompoundsTable  header={'Degradation Compounds of Product'} eventGroupToTrigger={'quality_safety'} />
                                <StabilityTableCard  header={'Stability compounds'} eventGroupToTrigger={'quality_safety'} />

                            </div>
                            <div className="col-3 col-sm-3">
                                <ProductDegradationCard header={'Product Degradation Parameters'} eventGroupToTrigger={'quality_safety'} />
                                <ProductDegradationCompoundCard header={'Compound Degradation'} eventGroupToTrigger={'quality_safety'} />
                                <StabilityCard  header={'Stability compounds'} eventGroupToTrigger={'quality_safety'} />
                            </div>
                        </div>
                    </TabPanel>

                    {/* Products */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <MarketCBDTable header={'CBD Market'}  eventGroupToTrigger={'products'}/>
                                <PhysicalCharacterizationCBDTable header={'Physical Charac. CBD Table'}  eventGroupToTrigger={'products'}/>
                                <ProductsAnalysisMethodTable header={'List of Analysis Methods'} eventGroupToTrigger={'products'} />
                                <ProductAnalysisValuesTable header={'List of Products Analysis Values'} eventGroupToTrigger={'products'} />
                                <ProductsAnalysisReportsTable header={'List of Analysis Reports'} eventGroupToTrigger={'products'} />

                            </div>
                            <div className="col-3 col-sm-3">
                                <MarketCBDCard      header={'CBD Product'}  eventGroupToTrigger={'products'}  />
                                <PhysicalCharacterizationCard header={'Physical Charac. CBD'}  eventGroupToTrigger={'products'}  />
                                <ProductAnalisysMethodCard header={'Analysis Method'} eventGroupToTrigger={'products'} />
                                <ProductAnalisysReportCard header={'Analysis Report'} eventGroupToTrigger={'products'} />
                                <ProductAnalisysValueCard header={'Product Analysis'} eventGroupToTrigger={'products'} />

                            </div>
                        </div>
                    </TabPanel>

                    {/* Regulations */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                            </div>
                            <div className="col-3 col-sm-3">
                            </div>
                        </div>
                    </TabPanel>

                    {/* Patents */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                            </div>
                            <div className="col-3 col-sm-3">
                            </div>
                        </div>
                    </TabPanel>

                    {/* References */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                            <div className="col-9 col-sm-9">
                                <ReferenceTableCard         header={'References'}      eventGroupToTrigger={'references'}/>
                            </div>
                            <div className="col-3 col-sm-3">
                                <ReferenceCard              header={'Reference'}      eventGroupToTrigger={'references'}/>
                            </div>
                        </div>
                    </TabPanel>

                    {/* Basket */}

                    <TabPanel forceRender={true}>
                        <div className="row" >
                             <AllBaskets />
                        </div>
                    </TabPanel>

                    {/* Loggers / Audit Trail */}

                    <TabPanel forceRender={true}>
                            <div className="row" >
                                { !isReadOnly && (

                                    <div className="col-9 col-sm-9">
                                    <UploadsCard header={'Bulk Imports'} eventGroupToTrigger={'loggers'} showBasket={false} storageValue={"uploads-logger"} showContent={true}/>
                                    <AuditTrailCard header={'Audit Trail'} eventGroupToTrigger={'loggers'} showBasket={false} storageValue={"audit-logger"}/>

                                </div>
                                )}

                                <div className="col-3 col-sm-3">
                                    <StadisticsCard header={'Stadistics'} eventGroupToTrigger={'loggers'} showBasket={false}  storageValue={"stadistics-logger"}/>
                                    <ChangeLog header={'Change Log'} eventGroupToTrigger={'loggers'} showBasket={false}  storageValue={"change-logger"}/>
                                </div>
                            </div>
                        </TabPanel>

                </Tabs>

            <div className="buttonOnTabs">


                <WizardSearch data={listsOfObjects} />

                { !isReadOnly && (<CreateData />)}

                <Tooltip title="Reset All data views" placement="top" arrow>
                    <Button
                        style={{ height: '32px',  boxShadow:'0px 0px 0px 0px'}}
                        variant="contained"
                        color="primary"
                        onClick={clearAll}
                        aria-label={"Reset All data views"}>
                       <HighlightOffIcon />
                    </Button>
                </Tooltip>
            </div>

            </ContextMessage>


        </div>
          </LocalizationProvider>
      </StorageProvider>
  );
}

export default App;
