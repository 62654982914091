import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { FileDownload as FileDownloadIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { PHARMACO_DYNAMICS, getObjectsByRegEx, getByFilters } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from '../../types';
import Button from "@mui/material/Button";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { isValidCASNumber, isValidIchiKey } from "../../../utils/string";
import { PharmacoDynamics } from "./types";

const _PharmacoDynamicsTable: React.FC<iBaseInnerCardProps> = ({ data,
                                                                   isActive,
                                                                   setColumnFilters, columnFilters,
                                                                   searchListByFilters,
                                                                   searchListBy }) => {
    const UUID = 'PharmacoDynamicsTable';

    const columnNames = Object.keys(PharmacoDynamics);
    const columnsToView = ['COMPOUND_NAME', 'MEASURE_TYPE', 'MEASURE_RELATION', 'MEASURE_VALUE', 'MEASURE_UNITS', 'TARGET_NAME'];
    const columns = getColumns(PharmacoDynamics);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        if (isValidIchiKey(text)) {
            searchListBy?.(getObjectsByRegEx, PHARMACO_DYNAMICS, 'INCHIKEY', text);
        } else if (isValidCASNumber(text)) {
            searchListBy?.(getObjectsByRegEx, PHARMACO_DYNAMICS, 'CAS', text);
        } else {
            searchListBy?.(getObjectsByRegEx, PHARMACO_DYNAMICS, 'COMPOUND_NAME,ASSAY_DESCRIPTION,TARGET_NAME', text);
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string }) => {
        searchListBy?.(getObjectsByRegEx, PHARMACO_DYNAMICS, 'INCHIKEY', event.detail);
    }, [searchListBy]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === PHARMACO_DYNAMICS) {
            searchListByFilters?.(getByFilters, PHARMACO_DYNAMICS, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive, searchEvent, searchByInchiKeyEvent, searchWizard]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
            }}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_PHARMACO_DYNAMICS, row.original);
                            }}>
                        <VisibilityIcon />
                    </Button>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "PharmacoDynamics")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "PharmacoDynamics")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const PharmacoDynamicsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PharmacoDynamicsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PharmacoDynamicsTable);
