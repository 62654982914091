import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { COMPOUND, deleteDoc, getObjectByDoc, getObjectByField, getObjectsByRegEx, save } from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty, isNotUndefined, isValidCASNumber, isValidIchiKey } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { Compound } from "./types";

const _CompoundCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                          setError,
                                                          setData,
                                                          data,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc }) => {
    const UUID = '_CompoundCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(COMPOUND, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(COMPOUND, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(COMPOUND, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    // SEARCH FUNCTIONS (REORDERED)
    const searchByCASEvent = useCallback(async (event: { detail: string }) => {
        const result = await searchBy?.(getObjectsByRegEx, COMPOUND, 'CAS', '^' + event.detail + '$');
        const inchikey = result?.INCHIKEY;
        if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
    }, [searchBy]);

    const searchByInchiKeyEvent = useCallback((event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, COMPOUND, 'INCHIKEY', '^' + event.detail + '$');
    }, [searchBy]);

    const searchByTextEvent = useCallback(async (event: { detail: string }) => {
        const result: any = searchBy?.(getObjectsByRegEx, COMPOUND, 'COMPOUND_NAME,SYNONYMS', event.detail);
        const inchikey = result?.INCHIKEY;
        if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
    }, [searchBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        if (isValidCASNumber(event.detail)) {
            searchByCASEvent(event);
        } else if (isValidIchiKey(event.detail)) {
            searchByInchiKeyEvent(event);
        } else {
            searchByTextEvent(event);
        }
    }, [searchByCASEvent, searchByInchiKeyEvent, searchByTextEvent]);

    const searchByPubchemIDEvent = useCallback((event: { detail: string }) => {
        setIsShow(false);
        getObjectByField(COMPOUND, 'PUBCHEM_CID', event.detail)
            .then(response => {
                if (isNotUndefined(response) && isNotUndefined(response[0])) {
                    setData(response[0]);
                    setIsShow(true);
                    setTitle('PUBCHEM_CID:' + event.detail);
                }
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                setError(error);
            });
    }, [setData, setIsShow, setTitle, setError]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, COMPOUND, event.detail, true, 'COMPOUND_NAME');
        if (event.detail.CBD_ID) {
            Events.trigger(EventsType.SEARCH_BY_CBD_ID, event.detail.CBD_ID);
        }
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_CAS, searchByCASEvent, UUID);
            Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
            Events.on(EventsType.SEARCH_BY_COMPOUND, searchByCompoundEvent, UUID);
            Events.on(EventsType.SEARCH_BY_PUBCHEM_CID, searchByPubchemIDEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_COMPOUND, UUID);
            Events.off(EventsType.SEARCH_BY_PUBCHEM_CID, UUID);
        };
    }, [isActive, createEvent, searchEvent, searchByCASEvent, searchByInchiKeyEvent, searchByCompoundEvent, searchByPubchemIDEvent]);

    // RENDER
    return (
        <>
            {renderFields(Compound, data)}

            {!hasReadOnlyRole && (
                <>
                    <FormEditModal save={saveData}
                                   data={data}
                                   schema={Compound} />
                    <DisplayDeleteModal deleteDoc={deleteData}
                                        data={data}
                                        schema={Compound} />
                </>
            )}

            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const CompoundCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_CompoundCard}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(CompoundCard);
