import {useMemo} from "react";

interface CompoundMap {
    [compound: string]: { [key: string]: number };
}

interface CompoundOccurrences {
    [compound: string]: string[];
}

interface ProductTotals {
    [key: string]: number;
}

interface Dataset {
    label: string;
    data: number[];
    backgroundColor?: string;
    errorBars?: { [key: string]: { plus: number; minus: number } }; // Keep for compatibility, though not used directly now
}

export const defaultGenerateColors = (count: number) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
        const hue = (i * 137.5) % 360;
        const color = `hsl(${hue}, 70%, 50%)`;
        colors.push(color);
    }
    return colors;
};

export const defaultBuildLabels = (data: any[]): string[] => {
    return data.map((row) => {
        return `${row.NAME}`;
    });
};

export const transformData = (
    items: any[],
    selectedKeys: string[],
    mode: 'direct' | 'percentage' | 'normalized' | 'logarithmic',
    getKey: (item: any) => string,
    getValue: (row: any) => number,
    getCompound: (row: any) => string,
    getStdev: (row: any) => number | null,
    getUnit: (item: any) => string,
    showInCommon: boolean
): { labels: string[]; datasets: Dataset[] } => {
    const compoundsMap: CompoundMap = {};
    const compoundOccurrences: CompoundOccurrences = {};
    const productTotals: ProductTotals = {};
    const stdevMap: { [compound: string]: { [key: string]: number | null } } = {};

    items.forEach(item => {
        const key = getKey(item);
        if (selectedKeys.length === 0 || selectedKeys.includes(key)) {
            let totalForProduct = 0;
            item.DATA.forEach((row: any) => {
                const compound = getCompound(row) || 'Unknown';
                const value = getValue(row) || 0;
                if (value > 0 && compound !== 'Unknown') {
                    if (!compoundsMap[compound]) {
                        compoundsMap[compound] = {};
                        compoundOccurrences[compound] = [];
                        stdevMap[compound] = {};
                    }
                    compoundsMap[compound][key] = value;
                    totalForProduct += value;
                    stdevMap[compound][key] = getStdev(row);
                    if (!compoundOccurrences[compound].includes(key)) {
                        compoundOccurrences[compound].push(key);
                    }
                }
            });
            productTotals[key] = totalForProduct;
        }
    });

    const allCompounds = Object.keys(compoundsMap);
    const compoundsToShow = selectedKeys.length > 1 ?
        (showInCommon ?
            allCompounds.filter(compound => compoundOccurrences[compound].length === selectedKeys.length) :
            allCompounds.filter(compound => compoundOccurrences[compound].length < selectedKeys.length)) :
        allCompounds;

    const datasets = items
        .filter(item => {
            const key = getKey(item);
            return selectedKeys.length === 0 || selectedKeys.includes(key);
        })
        .map((item, index) => {
            const key = getKey(item);
            const totalForProduct = productTotals[key] || 0;
            const data = compoundsToShow.map(compound => {
                const value = compoundsMap[compound]?.[key] || 0;
                switch (mode) {
                    case 'direct':
                        return value;
                    case 'percentage':
                        return totalForProduct > 0 ? (value / totalForProduct) * 100 : 0;
                    case 'normalized':
                        const maxInProduct = Math.max(...Object.values(compoundsMap).map(map => map[key] || 0));
                        return maxInProduct > 0 ? value / maxInProduct : 0;
                    case 'logarithmic':
                        const logMaxInProduct = Math.max(...Object.values(compoundsMap).map(map => map[key] || 0));
                        return logMaxInProduct > 0 ? Math.log10(value + 1) / Math.log10(logMaxInProduct + 1) * 100 : 0;
                    default:
                        return value;
                }
            });
            const errorBars = compoundsToShow.reduce((acc, compound) => {
                const stdev = stdevMap[compound]?.[key];
                if (stdev !== null && stdev !== undefined) {
                    acc[compound] = { plus: stdev, minus: stdev };
                }
                return acc;
            }, {} as { [key: string]: { plus: number; minus: number } });

            return {
                label: key,
                data,
                backgroundColor: `hsl(${index * 60}, 70%, 50%)`,
                errorBars: Object.keys(errorBars).length > 0 ? errorBars : undefined,
            };
        });

    return {
        labels: compoundsToShow,
        datasets,
    };
};

// Extract unique CLASS values
export const useGetClassOptions = (filteredItems: any[]) : string[] => {
  return useMemo(() => {
      const classes = new Set<string>();
      filteredItems.forEach(item => {
          item.DATA.forEach((row: any) => {
              if (row.CLASS) classes.add(row.CLASS);
          });
      });
      return ['All', ...Array.from(classes)];
  }, [filteredItems]);
}

// Filter data by selected CLASS
export const useGetFilteredItemsByClass = (filteredItems: any[], selectedClass:string) =>{
    return useMemo(() => {
        if (selectedClass === 'All') return filteredItems;
        return filteredItems.map(item => ({
            ...item,
            DATA: item.DATA.filter((row: any) => row.CLASS === selectedClass),
        })).filter(item => item.DATA.length > 0);
    }, [filteredItems, selectedClass]);
};


export const getTooltipCallbacks = (
    items: any[],
    getKey: (item: any) => string,
    getCompound: (row: any) => string,
    getValue: (row: any) => number,
    getStdev: (row: any) => number | null,
    getUnit: (item: any) => string,
    mode: 'direct' | 'percentage' | 'normalized' | 'logarithmic'
) => ({
    label: (context: any) => {
        const dataset = context.dataset;
        const index = context.dataIndex;
        const transformedValue = context.parsed.y;
        const compound = context.chart.data.labels[index];
        const key = dataset.label;
        const item = items.find(i => getKey(i) === key);
        const row = item?.DATA.find((r: any) => getCompound(r) === compound);
        const directValue = row ? getValue(row) : 0;
        const stdev = row ? getStdev(row) : null;
        const unit = item ? getUnit(item) : 'Unknown';
        const stdevText = stdev !== null ? ` ±${stdev.toFixed(2)}` : '';
        const modeUnit = mode === 'percentage' ? '%' :
            mode === 'normalized' ? '%' :
                mode === 'logarithmic' ? 'Log(%)' : unit;

        return [
            `${dataset.label}`,
            `Amount: ${directValue.toFixed(2)} ${stdevText} ${unit}`,
            `${mode.charAt(0).toUpperCase() + mode.slice(1)} Value: ${transformedValue.toFixed(2)} ${modeUnit}`
        ];
    }
});
