import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { PRODUCTS_ANALYSIS_REPORTS, deleteDoc, getObjectByDoc, getObjectsByRegEx, save } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { ProductAnalysisReport } from "./types";

const _PAReportCard: React.FC<iBaseInnerCardProps> = ({
                                                          setIsShow,
                                                          setError,
                                                          setData,
                                                          data,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc
                                                      }) => {
    const UUID = '_PAReportCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback((data: any) => {
        save(PRODUCTS_ANALYSIS_REPORTS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
                setTitle(`${res.ANALYSIS_TYPE || ''} ${res.REPORT_PROJECT_ID || ''}`.trim());
            })
            .catch(setError);
    }, [setData, setIsShow, setTitle, setError]);

    const deleteData = useCallback((data: any) => {
        deleteDoc(PRODUCTS_ANALYSIS_REPORTS, data)
            .then((res) => {
                setData({});
                setIsShow(false);
                setTitle('');
            })
            .catch(setError);
    }, [setData, setIsShow, setTitle, setError]);

    const undo = useCallback((undoData: any) => {
        Object.assign(data, undoData);
        save(PRODUCTS_ANALYSIS_REPORTS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
                setTitle(`${res.ANALYSIS_TYPE || ''} ${res.REPORT_PROJECT_ID || ''}`.trim());
            })
            .catch(setError);
    }, [data, setData, setIsShow, setTitle, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchByTextEvent = useCallback(async (event: { detail: string }) => {
        searchBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_REPORTS,
            'ANALYSIS_TYPE,SOURCE,REPORT_PROJECT_ID,MATRIX_CODE,SAMPLE_PREPARATION,PRODUCT', event.detail);
    }, [searchBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        searchByTextEvent(event);
    }, [searchByTextEvent]);

    const searchByObjectEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, PRODUCTS_ANALYSIS_REPORTS, event.detail, true, 'PRODUCTS ANALYSIS REPORTS');
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, searchByObjectEvent, UUID);
        }
        // Set initial TITLE when data loads
        if (data && (data.ANALYSIS_TYPE || data.REPORT_PROJECT_ID)) {
            setTitle(`${data.ANALYSIS_TYPE || ''} ${data.REPORT_PROJECT_ID || ''}`.trim());
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, UUID);
        };
    }, [isActive, data, createEvent, searchEvent, searchByObjectEvent, setTitle]);

    // RENDER
    return (
        <>
            {renderFields(ProductAnalysisReport, data)}
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal save={saveData} data={data} schema={ProductAnalysisReport} />
                    <DisplayDeleteModal deleteDoc={deleteData} data={data} schema={ProductAnalysisReport} />
                </>
            )}
            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const ProductAnalisysReportCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                                     header,
                                                                                                     width,
                                                                                                     eventGroupToTrigger
                                                                                                 }) => {
    return (
        <CardWithBaseComponent
            cardComponent={_PAReportCard}
            header={header}
            width={width}
            eventGroupToTrigger={eventGroupToTrigger}
        />
    );
};

export default React.memo(ProductAnalisysReportCard);
