import React, { useCallback, useEffect } from "react";
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import Events from "../../../events";
import { EventsType } from "../../../events-types";
import { removeHtmlTags } from "../../../utils/string";
import { getClinicalTrialByID } from "../../../api/ClinicalTrials";
import { isNotEmpty } from "../../../utils/string";
import { iClinicalTrial } from "./types";

const _ClinicalTrialCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, isActive }) => {
    const UUID = '_ClinicalTrialCard';

    // SEARCH FUNCTIONS
    const searchByID = useCallback(async (id: string) => {
        setIsShow(false);
        setTitle(id);
        getClinicalTrialByID(id)
            .then(_data => {
                if (isNotEmpty(_data)) {
                    const item: iClinicalTrial = {
                        NCT_ID: _data.protocolSection.identificationModule.nctId,
                        TITLE: removeHtmlTags(_data.protocolSection.identificationModule.officialTitle),
                        STATUS: _data.protocolSection.statusModule.overallStatus,
                        MAIN_SPONSOR: _data.protocolSection.sponsorCollaboratorsModule.leadSponsor.name,
                        PHASE: _data.protocolSection.designModule.phases.join(','),
                        SUMMARY: _data.protocolSection.descriptionModule.briefSummary,
                        DESCRIPTION: _data.protocolSection.descriptionModule.detailedDescription,
                    };
                    setData(item);
                    setIsShow(true);
                }
            })
            .catch((error: any) => {
                setError(error);
                setIsShow(true);
            });
    }, [setIsShow, setTitle, setData, setError]);

    const searchEvent = useCallback((event: { detail: string }) => {
        searchByID(event.detail);
    }, [searchByID]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH_NCT_ID, searchEvent, UUID);
        }

        return () => {
            Events.off(EventsType.SEARCH_NCT_ID, UUID); // Corrected to match the event type used in Events.on
        };
    }, [isActive, searchEvent]);

    // RENDER
    return (
        <>
            <b>TITLE</b>: {data.TITLE}<br />
            <b>STATUS</b>: {data.STATUS}<br />
            <b>PHASE</b>: {data.PHASE}<br />
            <b>MAIN SPONSOR</b>: {data.MAIN_SPONSOR}<br />
            <b>SUMMARY</b>: {data.SUMMARY}<br />
            <b>DESCRIPTION</b>: {data.DESCRIPTION}<br />
        </>
    );
};

const ClinicalTrialCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ClinicalTrialCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ClinicalTrialCard);
