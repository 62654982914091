const awsmobile: { Auth: { Cognito: { userPoolId: string; userPoolClientId: string; region?: string; loginWith?: { oauth: { domain: string; scopes: string[]; redirectSignIn: string[]; redirectSignOut: string[]; responseType: 'code' | 'token' } } } } } = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!.split('/').pop() as string,
            userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
            region: process.env.REACT_APP_PROJECT_REGION as string, // Added back from original
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_DOMAIN as string,
                    redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_SIGIN as string],
                    redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_SIGOUT as string],
                    scopes: ['email', 'openid', 'profile'], // Changed from scope to scopes
                    responseType: 'code' as const // Ensure literal type
                }
            }
        }
    }
};

export default awsmobile;
