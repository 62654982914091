import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    PHARMACO_DYNAMICS,
    getObjectByDoc,
    save,
    deleteDoc,
} from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { PharmacoDynamics } from "./types";

const _PharmacoDynamicsCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, isActive, searchBy, searchByDoc }) => {
    const UUID = '_PharmacoDynamicsCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACKS
    const saveData = useCallback(async (data: any) => {
        save(PHARMACO_DYNAMICS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const deleteData = useCallback(async (data: any) => {
        deleteDoc(PHARMACO_DYNAMICS, data)
            .then((res) => {
                setData({});
                setIsShow(false);
            })
            .catch(setError);
    }, [setData, setIsShow, setError]);

    const undo = useCallback(async (undoData: any) => {
        Object.assign(data, undoData);
        save(PHARMACO_DYNAMICS, data)
            .then((res) => {
                setData(res);
                setIsShow(true);
            })
            .catch(setError);
    }, [data, setData, setIsShow, setError]);

    // EVENT HANDLERS
    const createEvent = useCallback((event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    }, [saveData]);

    const searchByCompoundEvent = useCallback((event: { detail: any }) => {
        searchByDoc?.(getObjectByDoc, PHARMACO_DYNAMICS, event.detail, true, 'COMPOUND_NAME');
    }, [searchByDoc]);

    // EFFECTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);

        if (isActive) {
            Events.on(EventsType.SEARCH_BY_PHARMACO_DYNAMICS, searchByCompoundEvent, UUID);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH_BY_PHARMACO_DYNAMICS, UUID);
        };
    }, [isActive, createEvent, searchByCompoundEvent]);

    // RENDERS
    return (
        <p>
            {renderFields(PharmacoDynamics, data)}
            <AuditModal id={data._id} undo={undo} />
            {!hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        data={data}
                        schema={PharmacoDynamics}
                        excludeListParameters={["COMPOUND_NAME"]}
                    />
                    <DisplayDeleteModal
                        deleteDoc={deleteData}
                        data={data}
                        schema={PharmacoDynamics}
                    />
                </>
            )}
        </p>
    );
};

const PharmacoDynamicsCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PharmacoDynamicsCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PharmacoDynamicsCard);
