import React, { useEffect, useState } from 'react';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getStadistics } from "../../../api/Audits";
import {formatNumberWithDecimalsAndSeparation} from "../../../utils/string";

const _StadisticsCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setData, data }) => {

    type TranslationKey = keyof typeof translations; // Define a type for possible keys

    const translations = {
        "Compounds": "Compounds",
        "Cannabinoids_Unique": "Cannabinoids",
        "Metabolites_Unique": "Metabolites",
        "Terpenes_Unique": "Terpenes",
        "Flavonoids_Unique": "Flavonoids",
        "Phenols_Unique": "Phenols",
        "Varieties_Unique": "Varieties",
        "CBD_Studies": "Studies",
        "CBD_Market": "Products on Market",
        "References": "References",
        "Cannabinoids": "References of Cannabis Compounds",
        "Metabolites": "Doses of Metabolites",
        "Aroma": "References of Aromas",
        "Varieties": "References of Varieties",
        "V_Concentrations": "Compounds Concentrations on Varieties",
        "Products_Analysis_Methods": "Product Analysis Methods",
        "Products_Analysis_Reports": "Reports of Analyzed Products",
        "Products_Analysis_Unique": "diff Compounds Analyzed",
        "Products_Analysis_Values": "Concentrations of Compounds in Products",
        "Products_Degradation": "Products Degradation Analyzed",
        "Products_Degradation_Compounds": "Products Degradation Compounds",
        "Mutagenicity": "Mutagenicities",
        "Stability": "materials analyzed for Stability"
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsShow(true);
            const fetchedData = await getStadistics();
            setData(fetchedData);
        };

        fetchData();
    }, []);

    return (
        <>
            {data.map((statistic: Record<TranslationKey, number>) => {
                const key = Object.keys(statistic)[0] as TranslationKey;
                return (
                    <div key={key}>
                        <b>{translations[key]} : </b> <span style={{fontSize:'large'}}>{formatNumberWithDecimalsAndSeparation(Object.values(statistic)[0], 0)}</span>
                    </div>
                );
            })}
        </>
    );
};

const StadisticsCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                         header,
                                                                                         width,
                                                                                         eventGroupToTrigger,
                                                                                         showBasket,
                                                                                         storageValue
                                                                                     }) => {
    const [data, setData] = useState<any[]>([]);

    return <CardWithBaseComponent cardComponent={_StadisticsCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  storageValue={storageValue}/>;
};

export default React.memo(StadisticsCard);
