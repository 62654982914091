import React, {useState, ChangeEvent, useEffect} from 'react';
import {isNotUndefined} from "../../utils/string";
import {useMessage} from "../ContextMessage";
import {useModal} from "./Modal";
import EditIcon from '@mui/icons-material/Edit';
import {IconButton} from "@mui/material";

interface DynamicModalProps<T> {
    defaultOpen?: boolean,
    schema: T;
    data: Partial<T>; // Partial type to allow partial data as initial values
    save: (doc: any) => any;
    excludeListParameters?: string[];
}

const FormEditModal = <T extends Record<string, any>>({defaultOpen=false, schema, data, save, excludeListParameters }: DynamicModalProps<T>) => {
    const [formValues, setFormValues] = useState<Partial<T>>(data);
    const { showWarning, showInfo } = useMessage();
    const { isOpen, handleOpenModal, handleCloseModal } = useModal();

    useEffect(() => {
        setFormValues(data);
    }, [data]);

    useEffect(() => {
        if (defaultOpen) {
            setFormValues({});
            handleOpenModal({});
        }
    }, [])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await save(formValues);
            showInfo('Compound value was saved successfully!');
        } catch (err) {
            showWarning('Compound value was NOT saved!');
        } finally {
            handleCloseModal();
        }
    };


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    };


    const filteredSchemaKeys = excludeListParameters ? Object.keys(schema).filter(key => !excludeListParameters.includes(key)) : Object.keys(schema);

    if (!isOpen && !defaultOpen) {
        return <>
            <IconButton
                title={"Edit"}
                onClick={() => handleOpenModal(data)}>
                <EditIcon/>
            </IconButton>
        </>;
    }

    return (
        <>
            {isNotUndefined(data) && isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="card-edit">
                            <form onSubmit={handleSubmit}>
                                {filteredSchemaKeys.map((property) => (
                                    <div key={property}>
                                        <label htmlFor={property}>{property}</label>
                                        <input
                                            type="text"
                                            id={property}
                                            name={property}
                                            value={formValues[property] !== undefined ? formValues[property] : ''}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="submit">Submit</button>
                                    <button type="button" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FormEditModal;
