import React, { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { generateUUID } from "../../utils/string";
import { ChartData, ChartPieProps } from "./types";
import { defaultGenerateColors, defaultBuildLabels } from "./Chart.Common";
import Chart from 'chart.js/auto'; // Import Chart to access registry

export const ChartPie: React.FC<ChartPieProps> = ({
                                                      data,
                                                      styleParam,
                                                      buildLabels = defaultBuildLabels,
                                                      generateColors = defaultGenerateColors
                                                  }) => {
    const UUID = generateUUID();

    const chartData: ChartData = {
        labels: buildLabels(data),
        datasets: [
            {
                data: data.map((row) => row.VALUE),
                backgroundColor: generateColors(data.length),
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    };

    useEffect(() => {
        return () => {
            const chartInstance = Chart.getChart(UUID);
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, [UUID]); // Cleanup on unmount or UUID change

    return (
        <>
            <Pie data={chartData} options={chartOptions} id={UUID} style={styleParam ? styleParam : {}} />
        </>
    );
};
