import * as XLSX from 'xlsx-js-style';
import {BidirectionalDictionary} from "./BiDictonary";
import {isNotUndefined} from "./string";

interface ExcelItem {
    TAB_NAME: string;
    DATA: any[] ;
    HEADER: string;
}

const componentsName = new BidirectionalDictionary<string, string>([
    ['PUBCHEM - Properties','PUBCHEM_Prop'],
    ['PUBCHEM - Component/Substances List','PUBCHEM_List'],
    ['PUBCHEM - Clinical Trials', 'PUBCHEM_CTrial'],
    ['Clinical Trials GOV', 'ClinicalTrials.gov'],
    ['Cannabis DB','CannabisDB'],
    ['Clinical Trial','ClinicalTrial'],
    ['CBD Product','CBDProduct'],
    ['CBD Market','CBDMarket'],
    ['Therapeutic Indications','Therap.Indic.'],
    ['Threapeutical Indications','Therap.Indic.List']
]);

function cleanNameDownload(text: string){
    const o = componentsName.get(text);

    if (o && isNotUndefined(o)){
        return o.value + text.replace(o.key, '')
    }
    else {
        return text;
    }
}


function cleanNameUpload(text:string) {

    const left = text.split(" ")[0];
    const right = text.split(" ")[1];
    const o = componentsName.get(left);


    if (o && isNotUndefined(o)){
        return { header: o.key , title: right };
    }
    else
    {
        return { header: left , title: right };;
    }
}


export function importExcelToBasket(data: any): ExcelItem[] {
    try {
        const workbook = XLSX.read(data, {type: 'binary'});
        const items: ExcelItem[] = [];

        workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];

            // Check if !ref is defined before decoding the range
            const range = worksheet['!ref'] ? XLSX.utils.decode_range(worksheet['!ref']) : {
                s: {r: 0, c: 0},
                e: {r: 0, c: 0}
            };

            // Extract column names from the first row
            const header: string[] = [];
            for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
                const cellAddress = XLSX.utils.encode_cell({r: range.s.r, c: colIndex});
                const cell = worksheet[cellAddress];
                if (cell && cell.t === 's') {
                    header.push(cell.v);
                } else {
                    header.push(`Column${colIndex + 1}`);
                }
            }

            // Parse the data, skipping the header row
            const data: any[] | Record<string, any> = XLSX.utils.sheet_to_json(worksheet, {
                header,
                range: XLSX.utils.encode_range({s: {r: range.s.r + 1, c: range.s.c}, e: range.e}),
            });

            const item: ExcelItem = {
                TAB_NAME:  cleanNameUpload(sheetName).title,
                DATA: Array.isArray(data) ? data : [data],
                HEADER: cleanNameUpload(sheetName).header
            };

            items.push(item);
        });

        return items;
    }
    catch (error) {
        console.error('Error reading Excel file:', error);
        // Handle the error, e.g., by returning an empty array or showing a user-friendly message
        return [];
    }
}


export function exportBasketToExcel(items: any[], fileName: string = 'basket_export' ) {
    const workbook = XLSX.utils.book_new();

    items.forEach((item, index) => {

        const { TAB_NAME, DATA, HEADER } = item;

        // SHEET
        const worksheet = XLSX.utils.json_to_sheet([]);
        worksheet['!autofilter'] = undefined; // Clear the autofilter
        worksheet['!merges'] = []; // Clear any merges

        const sheetName = cleanNameDownload(TAB_NAME).substring(0, 31).replace(/[\\/\\?*[\]]/g, '_');
        worksheet['!sheet'] = sheetName;


        //  HEADER
        XLSX.utils.sheet_add_json(worksheet, [HEADER], { origin: 'A1', skipHeader: true });

        // DATA
        if (Array.isArray(DATA)) {
            XLSX.utils.sheet_add_json(worksheet, DATA, { skipHeader: true, origin: 'A2' });
        } else if (typeof DATA === 'object') {
            const data = Object.entries(DATA).map(([key, value]) => ({ ['NAME']: key, ['VALUE']: value }));
            XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: 'A2' });
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });


    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const excelBlob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = fileName + '.xlsx';
    downloadLink.click();
}

export function exportToExcelSelectedRows(rows: any[], sheetName: string): void {
    let _data = rows.map((row) => row.original);
    exportToExcel(_data, sheetName);
}

export function exportToExcel(data: any[], sheetName: string): void {

    const transformedData = data.map((item) => {
        const transformedItem = { ...item };
        for (const key in transformedItem) {
            if (Array.isArray(transformedItem[key])) {
                transformedItem[key] = transformedItem[key].join(';');
            }
        }
        return transformedItem;
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(transformedData);

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    const filename = `Export_${sheetName}.xlsx`;

    XLSX.writeFile(wb, filename);
}
