import React, { useCallback, useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import {
    getChemicalAndPhysicalProperties,
    getAllDataByCID,
    getStructuresAndNames,
    getToxicity,
    getDisordersDiseases
} from "../../../api/Pubchem";
import { MaterialReactTable } from 'material-react-table';
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { isNotUndefined } from "../../../utils/string";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel } from "../../../utils/export_xlsx";
import { Pubchem } from "./types";

const _PubChemTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data,
                                                              setColumnFilters, columnFilters, isActive,
                                                              setTitle, storageValue }) => {
    const UUID = '_PubChemTableCard' + storageValue;
    const columnNames = Object.keys(Pubchem);
    const columnsToView = ['NAME', 'VALUES', 'DATA_TYPE'];
    const columns = getColumns(Pubchem);
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // HELPER FUNCTIONS
    const getMergeResults = useCallback((func: Function = (d: any) => d, data: any, TAG: string): any[] => {
        let _tmp = func(data);
        if (isNotUndefined(_tmp)) {
            return _tmp.map((obj: any) => {
                const newObj: any = {};
                for (let key in obj) {
                    newObj[key.toUpperCase()] = obj[key];
                }
                newObj["DATA_TYPE"] = TAG;
                return newObj;
            });
        } else {
            return [];
        }
    }, []);

    const _getToxicity = useCallback((data: any) => {
        let _tmp = getToxicity(data);
        let _toxicologicalProperties = getMergeResults(undefined, _tmp.toxicologicalProperties, 'TOXICOLOGICAL');
        let _ecologicalProperties = getMergeResults(undefined, _tmp.ecologicalProperties, 'ECOLOGICAL');
        const _data_merged = _toxicologicalProperties
            .concat(_ecologicalProperties)
            .filter((item: any) => item)
            .removeDuplicates();

        return _data_merged;
    }, [getMergeResults]);

    const _getProperties = useCallback((data: any) => {
        let _names = getMergeResults(getStructuresAndNames, data, 'NAMES');
        let _tmp = getChemicalAndPhysicalProperties(data);
        let _experimental = getMergeResults(undefined, _tmp.experimental, 'EXPERIMENTAL');
        let _predicted = getMergeResults(undefined, _tmp.predicted, 'PREDICTED');

        const _data_merged = _names
            .concat(_experimental)
            .concat(_predicted)
            .filter((item: any) => item)
            .removeDuplicates();

        return _data_merged;
    }, [getMergeResults]);

    const _getDisordersDiseases = useCallback((data: any) => {
        let _tmp = getMergeResults(getDisordersDiseases, data, 'DISORDERS & DISEASES');
        const _data_merged = _tmp
            .filter((item: any) => item)
            .removeDuplicates();

        return _data_merged;
    }, [getMergeResults]);

    const getDataByPubchemID = useCallback((pubchem_id: string) => {
        getAllDataByCID(pubchem_id)
            .then(response => {
                if (response.Fault) {
                    setError(response.Fault.Message);
                } else {
                    setTitle(response.Record.RecordTitle);
                    let _data_merged;

                    if (storageValue === 'toxicity') {
                        _data_merged = _getToxicity(response);
                    } else if (storageValue === 'disorders_diceases') {
                        _data_merged = _getDisordersDiseases(response);
                    } else {
                        _data_merged = _getProperties(response);
                    }

                    setData(_data_merged);
                }
                setIsShow(true);
            })
            .catch(ex => {
                setError(ex);
                setIsShow(true);
            });
    }, [setIsShow, setError, setTitle, setData, storageValue, _getToxicity, _getProperties, _getDisordersDiseases]);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        getDataByPubchemID(text);
    }, [getDataByPubchemID]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    // EFFECTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH_BY_PUBCHEM_CID, searchEvent, UUID);
        }

        return () => {
            Events.off(EventsType.SEARCH_BY_PUBCHEM_CID, UUID);
        };
    }, [isActive, searchEvent]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            initialState={{
                density: 'compact',
                columnVisibility: _initial_columns_state,
                columnOrder: [
                    'mrt-row-select',
                    'mrt-row-expand',
                    'DATA_TYPE',
                    'NAME',
                    'VALUES'
                ],
            }}
            data={data ?? []}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>Description: </b>{row.original.DESCRIPTION}</Typography>
                    {Array.isArray(row.original.VALUES) && row.original.VALUES.map((value: any) => (
                        <Typography><b>Value: </b>{value}</Typography>
                    ))}
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Pubchem Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "Pubchem Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const PubChemTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'> & { storageValue?: string }> = ({ header, width, storageValue, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PubChemTableCard} storageValue={storageValue} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PubChemTableCard);
