import React, { useCallback, useEffect } from 'react';
import { Box, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { FileDownload as FileDownloadIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { MaterialReactTable } from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { getByFilters, getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState } from "../../../utils/tables";
import { OnChangeFn, ColumnFiltersState } from "@tanstack/react-table";
import { exportToExcel, exportToExcelSelectedRows } from "../../../utils/export_xlsx";
import { ProductAnalysisMethod } from "./types";

const _PAMethodsTable: React.FC<iBaseInnerCardProps> = ({ data,
                                                            setColumnFilters, columnFilters,
                                                            isActive,
                                                            searchListBy, searchListByFilters }) => {
    const UUID = '_PAMethodsTable';

    const columnNames = Object.keys(ProductAnalysisMethod);
    const columnsToView = ['CLASS', 'TEST_METHOD_CODE', 'MATRIX_CODE'];

    const eventMapping: CellEventMapping = {
        'CLASS': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS,
        'TEST_METHOD_CODE': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE
    };

    const columns = getColumns(ProductAnalysisMethod, 2, createCellsWithEvents(eventMapping));
    const _initial_columns_state = getInitialColumnState(columnNames, columnsToView);

    // SEARCH FUNCTIONS
    const _search = useCallback((text: string) => {
        if (text.length > 0) {
            searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS,
                'CLASS,TEST_METHOD_CODE,MATRIX_CODE,METHOD_DESCRIPTION', text);
        }
    }, [searchListBy]);

    const searchEvent = useCallback((event: { detail: string }) => {
        _search(event.detail);
    }, [_search]);

    const searchWizard = useCallback(async (event: { detail: any }) => {
        if (event.detail.component === PRODUCTS_ANALYSIS_METHODS) {
            searchListByFilters?.(getByFilters, PRODUCTS_ANALYSIS_METHODS, event.detail.filters, 'wizard result');
        }
    }, [searchListByFilters]);

    const searchMethodCode = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS, 'TEST_METHOD_CODE', event.detail);
    }, [searchListBy]);

    const searchByReport = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS, 'MATRIX_CODE', event.detail.MATRIX_CODE);
    }, [searchListBy]);

    const searchByClass = useCallback(async (event: { detail: any }) => {
        searchListBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS, 'CLASS', event.detail);
    }, [searchListBy]);

    // EVENTS
    useEffect(() => {
        if (isActive) {
            Events.on(EventsType.SEARCH, searchEvent, UUID);
            Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE, searchMethodCode, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, searchByReport, UUID);
            Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS, searchByClass, UUID);
        }
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS, UUID);
        };
    }, [isActive, searchEvent, searchWizard, searchByClass, searchByReport, searchMethodCode]);

    // RENDER FUNCTIONS
    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters: any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            data={data ?? []}
            enableRowSelection
            enableColumnOrdering
            enableGlobalFilter={false}
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'CLASS', 'TEST_METHOD_CODE', 'MATRIX_CODE'
                ],
            }}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>Description:</b> {row.original.METHOD_DESCRIPTION}</Typography>
                </Box>
            )}
            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Compounds")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "ProductsAnalysisMethods")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const ProductsAnalysisMethodTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PAMethodsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductsAnalysisMethodTable);
